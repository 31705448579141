import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import {
  faChartBar,
  faFileExport,
  faInfo,
  faSearch,
  faTasks,
  faThumbsDown,
  faThumbsUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlignmentEnum, LiteTable } from 'react-lite-table';

const AnalysisTable = props => {
  const actions = row => {
    const tabName = props.tab.name;

    const approveButton = <Button
      variant="success"
      size="sm"
      title="Approve"
      onClick={ (e) => {
        e.stopPropagation();
        props.approveRow(row, tabName === 'MANUAL');
      } }>
      <FontAwesomeIcon icon={ faThumbsUp }/>
    </Button>;
    const declineButton = <Button
      variant="danger"
      size="sm"
      title="Decline"
      onClick={ (e) => {
        e.stopPropagation();
        props.declineRow(row, tabName === 'MANUAL');
      } }>
      <FontAwesomeIcon icon={ faThumbsDown }/>
    </Button>;
    const addExceptionButton = <Button
      variant="info"
      size="sm"
      data-kind="add-to-exceptions"
      title="Add to Exceptions"
      onClick={ (e) => {
        e.stopPropagation();
        props.addException(row);
      } }>
      <FontAwesomeIcon icon={ faFileExport }/>
    </Button>;
    const removeExceptionButton = <Button
      variant="warning"
      size="sm"
      data-kind="remove-from-exceptions"
      title="Remove from Exceptions"
      onClick={ (e) => {
        e.stopPropagation();
        props.removeException(row);
      } }>
      <FontAwesomeIcon icon={ faTimes }/>
    </Button>;
    const analyzeButton = <Button
      variant="primary"
      size="sm"
      title="Analyze"
      onClick={ (e) => {
        e.stopPropagation();
        props.showCheckForRow(row, true);
      } }>
      <FontAwesomeIcon icon={ faChartBar }/>
    </Button>;
    const sccpButton = <Button
      variant="primary"
      size="sm"
      title="SCCP"
      onClick={ (e) => {
        e.stopPropagation();
        props.sccpCheck(row);
      } }>
      <FontAwesomeIcon icon={ faInfo }/>
    </Button>;
    const checkButton = <Button
      variant="primary"
      size="sm"
      title="Check"
      onClick={ (e) => {
        e.stopPropagation();
        props.showCheckForRow(row);
      } }>
      <FontAwesomeIcon icon={ faTasks }/>
    </Button>;
    const detailsButton = <Button
      variant="primary"
      size="sm"
      title="Operator Details"
      onClick={ e => {
        e.stopPropagation();
        props.operatorDetails(row);
      } }>
      <FontAwesomeIcon icon={ faSearch }/>
    </Button>;

    const buttons = [];

    if (tabName !== 'EXCEPTIONS') {
      buttons.push(addExceptionButton);
    } else {
      buttons.push(removeExceptionButton);
    }

    if (tabName === 'MANUAL') {
      buttons.push(approveButton);
      buttons.push(declineButton);
    }

    if (props.isApprovable) {
      if (-1 !== [ 'WRONG_EPC', 'MISSING', 'FAILED' ].indexOf(tabName)) {
        buttons.push(approveButton);
      }

      if (-1 !== [ 'FOUND', 'MATCH', 'SUCCESSFUL' ].indexOf(tabName)) {
        buttons.push(declineButton);
      }
    }

    if (-1 !== [ 'MANUAL', 'EXCEPTIONS', 'FAILED' ].indexOf(tabName)) {
      buttons.push(analyzeButton);
    }

    if (tabName === 'FAILED') {
      buttons.push(checkButton);
    }

    if (props.isSccp && row.ir21_id) {
      buttons.push(sccpButton);
    }

    if (row.ir21_id) {
      buttons.push(detailsButton);
    }

    return buttons;
  }

  return <LiteTable
    rows={ props.rows }
    onFilter={ props.onFilter }
    onRowClick={ props.onRowClick }
    columns={ props.columns }
    actions={ actions }
    actionsAlignment={ AlignmentEnum.LEFT }
    withFiltering
    onFilterProvideColumn="id"
    cellValueResolver={ (row, column) => {
      if ((column === 'forwarders' || column === 'ip_address') && row[column]) {
        try {
          return JSON.parse(row[column]).join("\n");
        } catch (e) {
        }
      }

      return row[column];
    } }/>;
};

AnalysisTable.propTypes = {
  addException: PropTypes.func.isRequired,
  removeException: PropTypes.func.isRequired,
  operatorDetails: PropTypes.func.isRequired,
  showCheckForRow: PropTypes.func.isRequired,
  sccpCheck: PropTypes.func.isRequired,
  approveRow: PropTypes.func.isRequired,
  declineRow: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  isApprovable: PropTypes.bool.isRequired,
  isSccp: PropTypes.bool.isRequired,
  tab: PropTypes.object.isRequired,
};

export default AnalysisTable;
