import React, { Component } from 'react';
import connect from 'react-redux/lib/connect/connect';

import axios from '../../services/axios';
import classes from './ProfileWidget.module.css';
import { userUpdate } from '../../store/actions/user';

class ProfileWidget extends Component {
  componentDidMount() {
    axios.get('/profile/details').then((response) => {
      this.props.onUserFetch(response.data);
    });
  }

  render() {
    return <>
      <img
        className={ classes.roundPicture }
        src={ this.props.user && this.props.user.profileImage ? '/images/' + this.props.user.profileImage : '/react/images/defaultAvatar.png' }
        alt="Roaming Audit logo"/>
      {
        this.props.user
          ?
          <a className={ classes.userName }
             href="/profile">{ this.props.user.firstName + ' ' + this.props.user.lastName }</a>
          : null
      }
    </>;
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserFetch: user => dispatch(userUpdate(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileWidget);
