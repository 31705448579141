import { EVENT_SUBSCRIBE, EVENT_UNSUBSCRIBE } from '../actions/actionTypes';

const initialState = {
  observers: {},
};

const reducer = (state = initialState, action) => {
  if (action.type === EVENT_SUBSCRIBE) {
    if (typeof action.observer !== 'function') {
      throw new Error('[Events] Observer is not a function.');
    }

    const newState = { ...state };
    if (!state.observers[action.eventType]) {
      newState.observers[action.eventType] = [];
    } else {
      const index = newState.observers[action.eventType].findIndex(observer => {
        return observer === action.observer;
      });

      if (index !== -1) {
        return state;
      }
    }

    newState.observers[action.eventType].push(action.observer);

    return newState;
  }

  if (action.type === EVENT_UNSUBSCRIBE) {
    if (typeof action.observer !== 'function') {
      throw new Error('[Events] Observer is not a function.');
    }

    const newState = { ...state };

    const index = newState.observers[action.eventType].findIndex(observer => {
      return observer === action.observer;
    });

    if (index !== -1) {
      return state;
    }

    delete newState.observers[action.eventType][index];

    newState.observers[action.eventType] = newState.observers[action.eventType].filter(_ => _ !== undefined);

    return newState;
  }

  return state;
};

export default reducer;
