import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import BulkUpdateBriefTable from './BulkUpdateBriefTable/BulkUpdateBriefTable';

const BulkUpdateBriefModal = props => {
  const [isRemovalAllowed, setIsRemovalAllowed] = useState(false);

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button
          variant="primary"
          onClick={ () => props.submitActionHandler(props.query) }
          disabled={ !isRemovalAllowed }>
          Remove
        </Button>
      </>
    }
    hideModal={ props.hideModal }
    title={ `Bulk update ${props.subtypeName}` }>
    <BulkUpdateBriefTable
      columns={ props.columns }
      rows={ props.rows }
      checkboxesHandler={ checked => {
        setIsRemovalAllowed(checked.length > 0);
        props.checkboxesHandler(checked);
      } }
      reviewDetails={ props.reviewBulkDetails }
      query={ props.query }/>
  </Modal>;
}

BulkUpdateBriefModal.propTypes = {
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  subtypeName: PropTypes.string.isRequired,
  checkboxesHandler: PropTypes.func.isRequired,
  reviewBulkDetails: PropTypes.func.isRequired,

};

export default BulkUpdateBriefModal;
