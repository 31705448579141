import React, { Component, createRef } from 'react';
import { Button, Form, Jumbotron } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';
import { ButtonSpinner } from 'react-ui-utils';

import axios from '../../../services/axios';

import classes from './SignInView.module.css';

class SignInView extends Component {
  form = createRef();
  schema = null;
  token = null;

  componentDidMount() {
    this.schema = yup.object({
      username: yup.string()
        .email('Should be a valid email address.')
        .max(5, 'Email can be max 255 characters long.')
        .required('Email is a required field.'),
      password: yup.string()
        .max(4096, 'Password can be max 4096 characters long.')
        .required('Password is a required field.')
    });

    const token = document.head.querySelector('meta[name="csrf-token"]');
    if (token) {
      this.token = token.content;
    }
  }

  async doSignIn(data, { setStatus, setErrors }) {
    setStatus(null);

    try {
      await axios.post('sign-in/validate', data);
      this.form.current.submit();
    } catch (e) {
      if (e.response && e.response.status === 419) {
        setStatus('Page has expired. Please refresh.');
        return;
      }

      if (e.response && e.response.data && e.response.data.error) {
        setStatus(e.response.data.error);
        setErrors({});
        return;
      }

      setStatus('Authentication failed. Please try again later.');
    }
  }

  render() {
    return <Jumbotron className={ classes.formWrapper }>
      <h2 className={ classes.logInHeader }>Login to Your Account</h2>
      <Formik initialValues={ {} } validationSchema={ this.schema } onSubmit={ this.doSignIn.bind(this) }>
        {
          (formik) => {
            return <Form
              method="POST"
              ref={ this.form }
              onSubmit={ formik.handleSubmit }>
              <div className={ classes.formStatus }>{ formik.status }</div>
              <Form.Control type="hidden" name="_token" value={ this.token }/>
              <Form.Group controlId="roamingaudit__auth-email">
                <Form.Control
                  type="email"
                  name="username"
                  placeholder="Email"
                  value={ formik.values.username }
                  onChange={ formik.handleChange }
                  isValid={ formik.touched.username && !formik.errors.username && !formik.status }
                  isInvalid={ !!formik.errors.username || formik.status }/>
                <Form.Control.Feedback type="invalid">
                  { formik.errors.username }
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="roamingaudit__auth-password">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={ formik.values.password }
                  onChange={ formik.handleChange }
                  isValid={ formik.touched.password && !formik.errors.password && !formik.status }
                  isInvalid={ !!formik.errors.password || formik.status }/>
                <Form.Control.Feedback type="invalid">
                  { formik.errors.password }
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                className={ classes.logInButton }
                disabled={ formik.isSubmitting }
                variant="warning"
                type="submit"
                block>
                { formik.isSubmitting ? <ButtonSpinner/> : null }
                Login
              </Button>
              <Form.Text className="text-muted">
                <a className={ classes.forgotPasswordLink } href="/auth/sendEmailForPassRes">Forgot password?</a>
              </Form.Text>
            </Form>
          } }
      </Formik>
    </Jumbotron>;
  }
}

export default SignInView;
