import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import connect from 'react-redux/lib/connect/connect';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classes from './TopPanel.module.css';

const TopPanel = props => {
  const operator = props.user ? props.user.operator.name : 'None selected';

  return <Row className={ classes.container }>
    <Col className={ classes.navigation }>
      <a href="/" className={ classes.homeLink } title="Home">
        <FontAwesomeIcon className={ classes.homeIcon } icon={ faHome } alt="Home"/>
      </a>
      <FontAwesomeIcon className={ classes.chevronIcon } icon={ faChevronRight }/>
      <h5 id="TopPanel__breadcrumbs-title" className={ classes.breadcrumbs }>{ props.title }</h5>
    </Col>
    <Col className={ classes.operator }>
      <span className={ classes.operatorLabel }>Operator:</span>
      <span className={classes.operatorName}>
      {
          props.user && props.user.role === 'ADMIN'
          ? <a href="/operators">{ operator }</a>
          : operator
      }
      </span>
    </Col>
  </Row>;
};

const mapStateToProps = state => {
  return {
    user: state.user.user,
    title: state.title.title
  };
};

export default connect(mapStateToProps)(TopPanel);
