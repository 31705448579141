import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-ui-utils';
import { Col, Row } from 'react-bootstrap';

import SteeringTable from '../SteeringTable/SteeringTable';
import SteeringModeEnum from '../../SteeringModeEnum';

import classes from './SteeringModal.module.css';

const SteeringModal = props => {
  const [ filteredQty, setFilteredQty ] = useState(props.rows.length);

  return <Modal
    size="xl"
    hideModal={ props.hideModal }
    title={
      <>
        <span>Duplicates</span>
        { props.mode === SteeringModeEnum.OPTIMIZED
          ? <span className={ classes.modeHighlighted }>(optimized)</span>
          : null
        }
      </>
    }>
    <Row>
      <Col xs="auto"
           className={ classes.panelCell }>
        Entries displayed:
      </Col>
      <Col xs="auto"
           className={ classes.panelCell }>
        { filteredQty }
      </Col>
    </Row>
    <SteeringTable
      rows={ props.rows }
      columns={ props.columns }
      onFilter={ qty => setFilteredQty(qty) }/>
  </Modal>;
};

SteeringModal.propTypes = {
  mode: PropTypes.oneOf([ SteeringModeEnum.MODIFIED, SteeringModeEnum.OPTIMIZED ]).isRequired,
  hideModal: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SteeringModal;
