import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import connect from 'react-redux/lib/connect/connect';

import { titleUpdate } from '../../store/actions/title';

const page = props => {
  useEffect(() => {
    const currentTitleParts = document.title.split('::');
    document.title = props.title + ' :: ' + currentTitleParts[1];

    props.onTitleUpdate(props.title);
  });

  const { title, ...rest } = props;
  return <Route {...rest} />;
};

const mapDispatchToProps = dispatch => {
  return {
    onTitleUpdate: title => dispatch(titleUpdate(title))
  };
};

export default connect(null, mapDispatchToProps)(page);
