import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LoaderProvider, ModalProvider, ModalRoot, NotificationsProvider } from 'react-ui-utils';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import 'react-lite-table/dist/index.css';
import 'react-ui-utils/dist/index.css';

import './index.css';
import App from './App';
import store from './store/store';
import { BUGSNAG_RELEASE_STAGE } from './constants';
import axios from './services/axios';

Bugsnag.start({
  releaseStage: BUGSNAG_RELEASE_STAGE,
  appVersion: 'test_tekelec',
  apiKey: '93c16b6636944b789e3ec1f2cb248297',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: [ 'production', 'staging' ],
  onError: async e => {
    let user = store.getState().user.user;
    if (!user) {
      try {
        const response = await axios.get('/profile/details');
        user = response.data;
      } catch (e) {
        user = null;
      }
    }

    e.addMetadata('user', user);
  }
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
    <Provider store={ store }>
      <BrowserRouter>
        <ModalProvider>
          <NotificationsProvider>
            <LoaderProvider>
              <App/>
            </LoaderProvider>
          </NotificationsProvider>
          <ModalRoot/>
        </ModalProvider>
      </BrowserRouter>
    </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
