import {
  faBell,
  faCalendarCheck,
  faChartArea,
  faChartBar,
  faChartPie,
  faClipboardList,
  faCloudDownloadAlt,
  faCog,
  faComments,
  faCube,
  faDatabase,
  faFileAlt,
  faFileExcel, faGamepad,
  faGlobe,
  faList,
  faMap,
  faMobileAlt,
  faNetworkWired,
  faPaste,
  faPoll,
  faRunning,
  faSearch,
  faServer,
  faShieldAlt,
  faShieldVirus,
  faSignal,
  faSignOutAlt,
  faSimCard,
  faUserFriends,
  faUsers,
  faUserShield,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';

const MenuConfig = [
  {
    label: 'Partner Management',
    icon: faGlobe,
    rootOf: 'partner',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER', 'LIMITED_USER' ],
    submenu: [
      {
        label: 'Roaming Partners',
        icon: faUserTie,
        href: '/partners',
        feature: 'partner',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER', 'LIMITED_USER' ]
      },
      {
        label: 'Roaming Profile',
        icon: faGlobe,
        href: '/roaming/profile',
        feature: 'partner',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER', 'LIMITED_USER' ]
      },
      {
        label: 'Coverage Map',
        icon: faMap,
        href: '/coverage',
        feature: 'partner',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
    ]
  },
  {
    label: 'Coordination Management',
    icon: faComments,
    rootOf: 'coordination',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ],
    submenu: [
      {
        label: 'SIM Management',
        icon: faSimCard,
        href: '/sim-management',
        feature: 'coordination',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT' ]
      },
      {
        label: 'Test Scheduling',
        icon: faCalendarCheck,
        href: '/communication',
        feature: 'coordination',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Collaboration',
        icon: faComments,
        href: '/communication',
        feature: 'coordination',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
    ]
  },
  {
    label: 'IR.21 Management',
    icon: faSignal,
    rootOf: 'ir21',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'LIMITED_USER', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ],
    submenu: [
      {
        label: 'IR.21 Database',
        icon: faDatabase,
        href: '/roaming/ir21',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER', 'LIMITED_USER' ]
      },
      {
        label: 'IR.21 Notifications',
        icon: faBell,
        href: '/ir21-notifications',
        feature: 'ir21',
        roles: [ 'ADMIN' ]
      },
      {
        label: 'IR.21 Exporting',
        icon: faFileExcel,
        href: '/export',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'GROUP_LEVEL_MANAGER', 'DEMO_PROSPECT' ]
      },
      {
        label: 'IR.21 Updates',
        icon: faCloudDownloadAlt,
        href: '/roaming/ir21-updates',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'IR.21 Dashboard',
        icon: faChartBar,
        href: '/ir21-dashboard',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Global Title',
        icon: faMobileAlt,
        href: '/global-titles',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Packet Data',
        icon: faCube,
        href: '/packet-data',
        feature: 'ir21',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Info Center',
        icon: faSignal,
        rootOf: 'ir21-info',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'LIMITED_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ],
        submenu: [
          {
            label: 'IR.21 Inbox',
            icon: faSignal,
            href: { pathname: '/info/ir21' },
            feature: 'ir21-info',
            roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'LIMITED_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
          },
          {
            label: 'AA.14 Inbox',
            icon: faSignal,
            href: { pathname: '/info/aa14' },
            feature: 'ir21-info',
            roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
          }
        ]
      }
    ]
  },
  {
    label: 'Configuration Management',
    icon: faCog,
    rootOf: 'configuration',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'LIMITED_USER', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ],
    submenu: [
      {
        label: 'Dashboard',
        icon: faChartBar,
        strictMatch: true,
        href: '/',
        feature: 'configuration',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Group Dashboard',
        icon: faChartArea,
        href: '/group-dashboard',
        feature: 'configuration',
        roles: [ 'ADMIN', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Network Elements',
        icon: faServer,
        href: '/configuration',
        feature: 'configuration',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Node Templates',
        icon: faPaste,
        href: { pathname: '/templates' },
        feature: 'configuration',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Audit Results',
        icon: faPoll,
        href: { pathname: '/audit/results' },
        feature: 'configuration',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'LIMITED_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
      },
      {
        label: 'Reports',
        icon: faClipboardList,
        href: '/reports',
        feature: 'configuration',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER', 'DEMO_USER' ]
      },
      {
        label: 'Run Audits',
        icon: faRunning,
        href: { pathname: '/audit' },
        strictMatch: true,
        feature: 'configuration',
        roles: [ 'ADMIN', 'GROUP_LEVEL_MANAGER', 'MANAGER' ]
      },
    ]
  },
  {
    label: 'Security Management',
    icon: faShieldAlt,
    rootOf: 'security',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_MANAGER', 'GROUP_USER' ],
    submenu: [
      {
        label: 'SS7 Security Audit',
        icon: faUserShield,
        href: '/security/ss7',
        feature: 'security',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_MANAGER', 'GROUP_USER' ]
      },
      {
        label: 'IP Security Audit',
        icon: faNetworkWired,
        href: { pathname: '/security/ip' },
        feature: 'security',
        roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'GROUP_MANAGER', 'GROUP_USER' ]
      },
      {
        label: 'Perfect Filter',
        icon: faShieldVirus,
        href: { pathname: '/security/filter' },
        feature: 'security',
        roles: [ 'ADMIN' ]
      },
    ]
  },
  {
    label: 'Steering',
    icon: faGamepad,
    href: { pathname: '/steering' },
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Users',
    icon: faUserFriends,
    href: '/user-details',
    roles: [ 'ADMIN', 'MANAGER', 'USER', 'DEMO_PROSPECT', 'GROUP_LEVEL_MANAGER', 'GROUP_LEVEL_USER' ]
  },
  {
    label: 'MSISDN Lookup',
    icon: faSearch,
    href: '/msisdn-lookup',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Operators',
    icon: faUserTie,
    href: { pathname: '/operators' },
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Operator Groups',
    icon: faUsers,
    href: '/operator-groups',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Master IR.21',
    icon: faDatabase,
    href: '/ir21',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Activity Logs',
    icon: faFileAlt,
    href: '/activity/logs',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Lists',
    icon: faList,
    href: '/lists',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Missing Data',
    icon: faChartPie,
    href: '/ir21/non-reax-data',
    roles: [ 'ADMIN' ]
  },
  {
    label: 'Logout',
    icon: faSignOutAlt,
    href: '/sign-out',
    roles: [ 'ADMIN', 'GROUP_LEVEL_MANAGER', 'MANAGER', 'GROUP_LEVEL_USER', 'USER', 'DEMO_PROSPECT', 'TRIAL_USER', 'LIMITED_USER', 'DEMO_USER' ]
  },
];

export default MenuConfig;
