import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Modal, ButtonSpinner } from 'react-ui-utils';

const AddRoamingProfileModal = props => {
  const form = createRef();

  const options = [
    "Live",
    "Not Live",
    "Testing",
    "Outbound",
    "Inbound",
    "Inbound (Out Testing)",
    "Outbound (In Testing)",
    "Hub - Outbound",
    "Hub - Inbound"
  ];

  const schema = yup.object({
    tadig: yup.string()
      .matches(/^[0-9a-z]+$/i, 'TADIG should contain only letters and digits.')
      .length(5, 'Should be exactly ${length} characters long.')
      .required('TADIG is a required field.')
  });

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      tadig: props.tadig,
      gsm: null,
      gsm_date: null,
      gprs: null,
      gprs_date: null,
      camel: null,
      camel_date: null,
      lte: null,
      lte_date: null
    },
    onSubmit: data => props.submitActionHandler(data, props.entryId)
  });

  return <Modal
    title="Add Roaming Profile"
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="add-to-roamingprofile-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Add
        </Button>
      </>
    }
    hideModal={ props.hideModal }>
    <Form id="add-to-roamingprofile-form"
          ref={ form }
          onSubmit={ formik.handleSubmit }>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="12"
          controlId='form-tadig'>
          <Form.Label>TADIG</Form.Label>
          <Form.Control
            type="text"
            name="tadig"
            value={ formik.values.tadig }
            onChange={ formik.handleChange }
            isValid={ formik.touched.tadig && !formik.errors.tadig }
            isInvalid={ !!formik.errors.tadig }
            placeholder="TADIG"/>
          <Form.Control.Feedback type="invalid">
            { formik.errors.tadig }
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-gsm'>
          <Form.Label>GSM</Form.Label>
          <Form.Control
            as="select"
            name="gsm"
            value={ formik.values.gsm }
            onChange={ formik.handleChange }>
            <option value="" selected/>
            {
              options.map((name, i) => <option value={ i }>{ name }</option>)
            }
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-gprs'>
          <Form.Label>GPRS</Form.Label>
          <Form.Control
            as="select"
            name="gprs"
            value={ formik.values.gprs }
            onChange={ formik.handleChange }>
            <option value="" selected/>
            {
              options.map((name, i) => <option value={ i }>{ name }</option>)
            }
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-gsm_date'>
          <Form.Control
            type="date"
            name="gsm_date"
            value={ formik.values.gsm_date }
            onChange={ formik.handleChange }/>
        </Form.Group>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-gprs_date'>
          <Form.Control
            type="date"
            name="gprs_date"
            value={ formik.values.gprs_date }
            onChange={ formik.handleChange }/>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-camel'>
          <Form.Label>CAMEL</Form.Label>
          <Form.Control
            as="select"
            name="camel"
            value={ formik.values.camel }
            onChange={ formik.handleChange }>
            <option value="" selected/>
            {
              options.map((name, i) => <option value={ i }>{ name }</option>)
            }
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-lte'>
          <Form.Label>LTE</Form.Label>
          <Form.Control
            as="select"
            name="lte"
            value={ formik.values.lte }
            onChange={ formik.handleChange }>
            <option value="" selected/>
            {
              options.map((name, i) => <option value={ i }>{ name }</option>)
            }
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-camel_date'>
          <Form.Control
            type="date"
            name="camel_date"
            value={ formik.values.camel_date }
            onChange={ formik.handleChange }/>
        </Form.Group>
        <Form.Group
          as={ Col }
          xs="6"
          controlId='form-lte_date'>
          <Form.Control
            type="date"
            name="lte_date"
            value={ formik.values.lte_date }
            onChange={ formik.handleChange }/>
        </Form.Group>
      </Form.Row>
    </Form>
  </Modal>;
}

AddRoamingProfileModal.propTypes = {
  entryId: PropTypes.number.isRequired,
  tadig: PropTypes.string.isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default AddRoamingProfileModal;
