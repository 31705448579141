import React, { Component } from 'react';
import { withLoader, withModals, withNotifications } from 'react-ui-utils';

import ElementTotalsModal from './ElementTotalsModal/ElementTotalsModal';
import axios from '../../../services/axios';

import classes from './ElementTotals.module.css';

class ElementsTotal extends Component {
  state = {
    modalFilters: {}
  };

  filterHandler(event, field) {
    const state = { ...this.state };
    state.modalFilters[field] = event.target.value;
    this.setState(state);
  }

  async loadStatDetails(statType) {
    this.props.showLoader();

    try {
      const response = await this.getStatDetails(statType);

      this.props.showModal(ElementTotalsModal, {
        stats: response.data,
        filterHandler: this.filterHandler.bind(this),
        filters: this.state.modalFilters
      });
      this.setState({ statDetails: response.data });
    } catch (e) {
      this.props.processError(e);
      this.setState({ statDetails: [] });
    }

    this.props.hideLoader();
  }

  getStatDetails(statType) {
    if (statType === 'totals') {
      return axios.get(
        '/audit/' + this.props.vendorId
        + '/' + this.props.typeId
        + '/' + this.props.subtypeId
        + '/totals'
      );
    }

    return axios.get(
      '/audit/' + this.props.vendorId
      + '/' + this.props.typeId
      + '/' + this.props.subtypeId
      + '/test/' + statType
    );
  }

  render() {
    return <>
      <table className={ classes.table }>
        {
          this.props.stats.map(
            stat =>
              stat.data !== ''
                ? <tr>
                  <td className={ classes.cell + ' ' + classes.nameCell }>
                    { stat.name }
                  </td>
                  <td className={ classes.cell }>
                    {
                      stat.key === 'testing' ?
                        stat.data.map(
                          test =>
                            <span className={ classes.testStats }>
                            <span onClick={ () => this.loadStatDetails(test.key) }
                                  className={ classes.testStat + ' ' + classes.statLink }>
                              { test.data }
                            </span>
                            <span className={ classes.testName }>{ test.name }</span>
                          </span>
                        ) :
                        <span className={ stat.key === 'totals' ? classes.statLink : '' }
                              onClick={ () => this.loadStatDetails(stat.key) }>
                        { stat.data }
                      </span>
                    }
                  </td>
                </tr>
                : null
          )
        }
      </table>
    </>;
  }
}

export default withModals(withNotifications(withLoader(ElementsTotal)));
