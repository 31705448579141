import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/lib/connect/connect';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import { ButtonSpinner, withLoader, withModals, withNotifications } from 'react-ui-utils';

import DropdownSearch from '../../../components/DropdownSearch/DropdownSearch';
import axios from '../../../services/axios';
import IpRangesTable from './IpRangesTable/IpRangesTable';
import IpGeolocationModal from './IpGeolocationModal/IpGeolocationModal';
import MapChart from '../../../components/MapChart/MapChart';

import classes from './IpSecurityView.module.css';

class IpSecurityView extends Component {
  state = {
    summaries: null,
    selectedVendor: null,
    selectedSummary: null,
    resultsRows: [],
    resultsColumns: [],
    chartData: null,
    maxIpsQty: 0,
    isPopulatingAll: false
  };

  componentDidMount() {
    this.fetchChartData();
    this.fetchVendors();
  }

  async fetchVendors() {
    this.props.showLoader();

    try {
      const response = await axios.get('/elements/ip-range');

      const keys = Object.keys(response.data);
      let selectedVendor = null;
      if (keys.length > 0) {
        selectedVendor = response.data[keys[0]];
      }

      this.setState(
        { vendors: response.data },
        () => {
          if (selectedVendor) {
            this.vendorChangeHandler(selectedVendor);
          }
        });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async vendorChangeHandler(vendor) {
    this.props.showLoader();

    this.setState({

      selectedVendor: vendor,
      summaries: null,
      resultsRows: [],
      resultsColumns: [],
      selectedSummary: null,
    });

    try {
      const response = await axios.get('/' + [ 'security', 'ip', 'summary', 'element', vendor.id ].join('/'));

      this.setState({ summaries: response.data, });
    } catch (e) {
      this.props.processError(e);

      this.setState({ summaries: null, });
    }

    this.props.hideLoader();
  }

  async lookupIpGeolocation(row) {
    this.props.showLoader();

    try {
      const response = await axios.put('/security/ip/geolocate', { ipRange: row.ip_address_range });

      this.props.showModal(IpGeolocationModal, {
        geolocationData: response.data,
        ipAddressRange: row.ip_address_range
      });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async fetchChartData() {
    try {
      const response = await axios.get('/security/ip/chart-data');

      this.setState({ chartData: response.data.chartData, maxIpsQty: response.data.maxIpsQty || 0 });
    } catch (e) {
      this.props.processError(e);
    }
  }

  async refreshGeolocation() {
    this.props.showLoader();

    this.setState({ isPopulatingAll: true });

    try {
      await axios.put('/security/ip/geolocate/all');
      await this.fetchChartData();
    } catch (e) {
      this.props.processError(e);
    }

    this.setState({ isPopulatingAll: false });

    this.props.hideLoader();
  }

  async networkElementChangeHandler(summary) {
    this.props.showLoader();

    this.setState({
      selectedSummary: summary,
      resultsRows: [],
      resultsColumns: []
    });

    try {
      const response = await axios.get(
        '/' + [
          'security', 'ip', 'summary', summary.id, 'element',
          this.state.selectedVendor.id, 'results'
        ].join('/')
      );

      this.setState({

        resultsRows: response.data ? response.data.rows : [],
        resultsColumns: response.data ? response.data.columns : [],
      });
    } catch (e) {
      this.props.processError(e);

      this.setState({

        resultsRows: [],
        resultsColumns: [],
        selectedSummary: null,
      });
    }

    this.props.hideLoader();
  }

  render() {
    return <>
      <Row>
        <Col xs="6">
          <Row>
            <Col xs="auto">
              {
                this.props.user && this.props.user.role === 'ADMIN'
                  ? <Button
                    variant="primary"
                    className={ classes.dashboardAction }
                    disabled={ this.state.isPopulatingAll }
                    title="Populate Geolocation"
                    onClick={ this.refreshGeolocation.bind(this) }>
                    { this.state.isPopulatingAll
                      ? <ButtonSpinner/>
                      : <FontAwesomeIcon icon={ faGlobe }/> }
                  </Button>
                  : null
              }
              <Button variant="info"
                      data-multiline={ true }
                      data-place="right"
                      data-type="info"
                      data-tip={
                        'Vulnerable IP Ranges are ranges that are not specified in IR.21 documents' +
                        '<br/>as being part of the "List of all IP address ranges used by PMN for connection to inter-PMN IP backbone'
                      }
                      className={ classes.dashboardAction }>
                <FontAwesomeIcon icon={ faQuestionCircle }/>
              </Button>
            </Col>
            <Col>
              <Row className={ classes.dashboardRow }>
                <Col xs="5">
                  <DropdownSearch
                    current={ this.state.selectedVendor }
                    dictionary={ this.state.vendors }
                    emptyDropdownTitle="Select Vendor"
                    changed={ this.vendorChangeHandler.bind(this) }
                    uniquePrefix="IpSecurity__vendor-selectbox"/>
                </Col>
              </Row>
              <Row className={ classes.dashboardRow }>
                <Col xs="5">
                  <DropdownSearch
                    current={ this.state.selectedSummary }
                    dictionary={ this.state.summaries }
                    emptyDropdownTitle="Select Network Element"
                    changed={ this.networkElementChangeHandler.bind(this) }
                    uniquePrefix="IpSecurity__network-element-selectbox"/>
                </Col>
              </Row>
              {
                this.state.selectedSummary
                  ? <Row>
                    <Col xs="5">
                      <Row>
                        <Col className={ classes.smallTableHeading }>
                          Vulnerable IP Ranges
                        </Col>
                        <Col xs="auto">
                          { this.state.summaries[this.state.selectedSummary.id].ipRangesQty }
                        </Col>
                      </Row>
                      <Row>
                        <Col className={ classes.smallTableHeading }>
                          Vulnerable IPs
                        </Col>
                        <Col xs="auto">
                          { this.state.summaries[this.state.selectedSummary.id].ipsQty }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  : null
              }
            </Col>
          </Row>
        </Col>
        <Col xs="6">
          {
            this.state.chartData
              ? <MapChart data={ this.state.chartData } minValue="1" maxValue={ this.state.maxIpsQty }/>
              : 'No data is available yet.'
          }
        </Col>
      </Row>
      {
        this.state.selectedSummary
          ? <IpRangesTable
              rows={ this.state.resultsRows }
              columns={ this.state.resultsColumns }
              lookup={ this.lookupIpGeolocation.bind(this) }/>
          : null
      }
      <ReactTooltip/>
    </>;
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user
  };
};

export default withLoader(withNotifications(withModals(withRouter(connect(mapStateToProps)(IpSecurityView)))));
