import React, { Component } from 'react';
import { withLoader, withNotifications } from 'react-ui-utils';

import Ir21InboxTable from './Ir21InboxTable/Ir21InboxTable';
import axios from '../../../services/axios';

class Ir21InboxView extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.fetchInbox();
  }

  async fetchInbox() {
    this.props.showLoader();

    try {
      const response = await axios.get('/info/ir21/inbox');

      this.setState({ data: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  render() {
    return <Ir21InboxTable rows={ this.state.data }/>;
  }
}

export default withLoader(withNotifications(Ir21InboxView));
