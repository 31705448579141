import { LiteTable } from 'react-lite-table';

const SccpCheckTable = props => <LiteTable
  rows={ props.rows }
  withFiltering
  columnsMap={ {
    'carrier_name': 'Carrier Name',
    'connectivity_information': 'Connectivity Information',
    'signature': 'Signature',
    'int_dpc': 'Int DPC',
    'comments': 'Comments',
    'effective_date_of_change': 'Date'
  } }/>;

export default SccpCheckTable;
