import React from 'react';
import { LiteTable } from 'react-lite-table';

const OptimizedFilterTable = props => <LiteTable
  rows={ props.rows }
  onFilter={ props.onFilter }
  columnsMap={ {
    'organization': 'Organization',
    'brand': 'Brand',
    'country': 'Country',
    'tadig': 'TADIG',
    'hub': 'Hub',
    'mcc': 'MCC',
    'mnc': 'MNC',
    'cc': 'CC',
    'ndc': 'NDC',
    'rangeStart': 'Start',
    'rangeStop': 'Stop',
    'newNdc': 'New NDC',
    'newRangeStart': 'New Start',
    'newRangeStop': 'New Stop',
    'gt': 'Optimized GT',
  } }
  withSorting
  withFiltering/>

export default OptimizedFilterTable;
