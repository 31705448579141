import React from 'react';
import BsTagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import PropTypes from 'prop-types';

import './TagsInput.css';

const TagsInput = props => {
  const inputProps = {};

  if (props.placeholder) {
    inputProps.placeholder = props.placeholder;
  }

  if (props.disabled) {
    inputProps.className = 'react-tagsinput-input react-tagsinput-input--disabled'
  }

  return <BsTagsInput
    className={ 'react-tagsinput' + (props.disabled ? ' react-tagsinput--disabled' : '') }
    validationRegex={ props.validationRegex }
    onValidationReject={ props.onValidationReject }
    onlyUnique={ !!props.onlyUnique }
    addOnBlur={ true }
    inputProps={ inputProps }
    disabled={ !!props.disabled }
    value={ props.value }
    onChange={ props.onChange }/>;
}

TagsInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onlyUnique: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validationRegex: PropTypes.string,
  onValidationReject: PropTypes.func
};

export default TagsInput;
