import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { LiteTable } from 'react-lite-table';

const BulkUpdateBriefTable = props => <LiteTable
  withCheckboxes
  columns={ props.columns }
  checkboxesHandler={ props.checkboxesHandler }
  checkboxKey="configurationId"
  rows={ props.rows }
  actions={ row => {
    return [
      <Button
        variant="primary"
        size="sm"
        title="Review"
        onClick={ e => {
          e.stopPropagation();
          props.reviewDetails(row, props.query);
        } }>
        <FontAwesomeIcon icon={ faSearch }/>
      </Button>
    ];
  } }/>

BulkUpdateBriefTable.propTypes = {
  query: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkboxesHandler: PropTypes.func.isRequired,
  reviewDetails: PropTypes.func.isRequired
};

export default BulkUpdateBriefTable;
