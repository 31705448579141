import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { LiteTable } from 'react-lite-table';

const UpdateImsiTable = props => <LiteTable
  rows={ props.rows }
  columnsMap={ {
    'valueLength': 'Length',
    'count': 'Count',
  } }
  withCheckboxes
  checkboxKey="valueLength"
  columns={ props.columns }
  checkboxesHandler={ props.checkboxesHandler }
  actions={ row => <Button
    variant="primary"
    size="sm"
    title="Review"
    onClick={ e => {
      e.stopPropagation();
      props.reviewDetails(row);
    } }>
    <FontAwesomeIcon icon={ faSearch }/>
  </Button> }/>

UpdateImsiTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkboxesHandler: PropTypes.func.isRequired,
  reviewDetails: PropTypes.func.isRequired
};

export default UpdateImsiTable;
