import { ENV_PROD, ENV_STAGING } from './env';

export let BUGSNAG_RELEASE_STAGE;

switch (process.env.REACT_APP_ENV) {
  case ENV_PROD:
    BUGSNAG_RELEASE_STAGE = 'production';
    break;
  case ENV_STAGING:
    BUGSNAG_RELEASE_STAGE = 'staging';
    break;
  default:
    BUGSNAG_RELEASE_STAGE = 'local';
    break;
}
