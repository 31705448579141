import React, { createRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ButtonSpinner, FileUploadControl, Modal } from 'react-ui-utils';

import ElementSelector from '../../../../components/ElementSelector/ElementSelector';

const BulkUploadModal = props => {
  const [ uploadLabel, setUploadLabel ] = useState('');
  const form = createRef();
  const formik = useFormik({
    initialValues: {
      subtype: null,
      type: null,
      vendor: null,
      bulkFile: null
    },
    onSubmit: data => props.submitActionHandler(data.vendor.id, data.type.id, data.subtype.id, data.bulkFile)
  });

  const onElementChange = (subtype, vendor, type) => {
    formik.setFieldValue('subtype', subtype);
    formik.setFieldValue('vendor', vendor);
    formik.setFieldValue('type', type);
  }

  return <Modal
    size="sm"
    hideModal={ props.hideModal }
    title="Bulk Upload"
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting || !formik.dirty || !formik.isValid }
                form="bulk-upload-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          Next
        </Button>
      </>
    }>
    <Form noValidate
          id="bulk-upload-form"
          ref={ form }
          onSubmit={ formik.handleSubmit }>
      <Form.Label>Associated Element</Form.Label>
      <ElementSelector
        size="12"
        elementChanged={ onElementChange }
        thirdName="subtype"
        secondName="vendor"
        firstName="type"
        elementsUrl="/elements/bulk"/>
      <Form.Group
        controlId="bulk-upload-file">
        <Form.Label>File</Form.Label>
        <FileUploadControl
          name="bulkFile"
          id="bulkFile"
          changeHandler={ file => formik.setFieldValue('bulkFile', file) }
          isInvalid={ !!formik.errors.bulkFile }
          isValid={ formik.touched.bulkFile && !formik.errors.bulkFile }/>
      </Form.Group>
    </Form>
  </Modal>;
};

BulkUploadModal.propTypes = {
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default BulkUploadModal;
