import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import classes from './Footer.module.css';
import TastesEnum from '../TastesEnum';

const Footer = props => <Row>
  <Col xs={ 12 } className={ classes.copyrightInfo + ' ' + classes['copyrightInfo--' + props.taste] }>
    Copyright &copy; Roaming Audit { (new Date()).getFullYear() } · All rights reserved
  </Col>
</Row>;

Footer.propTypes = {
  taste: PropTypes.oneOf([TastesEnum.GUEST, TastesEnum.CLIENT]).isRequired
};

export default Footer;
