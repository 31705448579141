import { TITLE_UPDATE } from '../actions/actionTypes';

const initialState = {
  title: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TITLE_UPDATE:
      return { ...state, title: action.title };
    default:
      return state;
  }
};

export default reducer;
