import React from 'react';
import { LiteTable } from 'react-lite-table';

const HeaderTable = props => <LiteTable
  rows={ props.rows }
  columns={ props.columns }
  columnsMap={ {
    'brand': 'Brand',
    'country': 'Country',
    'tadig': 'TADIG',
    'cc': 'CC',
    'ndc': 'NDC',
    'range_start': 'Start',
    'range_stop': 'Stop'
  } }/>

export default HeaderTable;
