import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { Modal } from 'react-ui-utils';

import { ExtraInfo } from './ExtraInfo';

class ExtraInfoModal extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  render() {
    return <Modal
      actions={
        <>
          <Button variant="secondary" onClick={ () => this.props.hideModal() }>Close</Button>
          <Button variant="primary"
                  id="extraInfoRunAuditButton"
                  onClick={ () => this.props.onConfirm(this.inputRef.current.value) }>
            Run Audit
          </Button>
        </>
      }
      hideModal={ this.props.hideModal }
      title={ this.props.extraInfo.title }>
      <input type="text" ref={ this.inputRef } placeholder={ this.props.extraInfo.placeholder }/>
    </Modal>;
  }
}

ExtraInfoModal.propTypes = {
  extraInfo: PropTypes.objectOf(ExtraInfo),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ExtraInfoModal;
