import { Button, Form } from 'react-bootstrap';
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Modal, ButtonSpinner } from 'react-ui-utils';

import DynamicField from '../../../components/DynamicField/DynamicField';

import classes from './ModifyEntryModal.module.css';

const ModifyEntryModal = props => {
  const form = createRef();
  let initial;
  if (props.entryFields.indexOf('anres') !== -1) {
    initial = props.existingEntry || { anres: {} };
    if (!initial.anres) {
      initial.anres = {};
    }
  } else {
    initial = props.existingEntry || {};
  }

  const formik = useFormik({
    initialValues: initial,
    onSubmit: data => props.submitActionHandler(data, props.isEditing)
  });

  return <Modal
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button variant="primary"
                type="submit"
                disabled={ formik.isSubmitting }
                form="modify-template-entry-form">
          { formik.isSubmitting ? <ButtonSpinner/> : null }
          { props.isEditing ? 'Update' : 'Add' }
        </Button>
      </>
    }
    size="sm"
    hideModal={ props.hideModal }
    title={ props.isEditing ? 'Update Entry' : 'Add Entry' }>
      <Form noValidate
            id="modify-template-entry-form"
            ref={ form }
            onSubmit={ formik.handleSubmit }>
        {
          props.entryFields.filter(field => field !== 'anres').map(
            field => <Form.Group
              controlId={ 'form-' + field }
              className={ classes.formGroups }>
              <Form.Label className={ classes.label }>
                { field.replace(/_/g, ' ') }
              </Form.Label>
              <Form.Control
                className={ classes.input }
                type="text"
                name={ field }
                value={ formik.values[field] }
                onChange={ formik.handleChange }
                placeholder={ field.toUpperCase() }/>
            </Form.Group>
          )
        }
        {
          props.anresFields.length > 0
            ? <DynamicField
              formik={ formik }
              formName="add-template-entry"
              fieldName="anres"
              availableFields={ props.anresFields }
              currentEntry={ props.entryFields }/>
            : null
        }
        {
          props.chars.length > 0
            ? <>
              {
                props.chars.map(char =>
                  <div className={ classes.charsOption } onClick={ () => formik.setFieldValue('chars', char) }>
                  { char }
                </div>
                )
              }
            </>
            : null
        }
      </Form>
  </Modal>;
};

ModifyEntryModal.propTypes = {
  anresFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  chars: PropTypes.arrayOf(PropTypes.string).isRequired,
  entryFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideModal: PropTypes.func.isRequired,
  existingEntry: PropTypes.object,
  isEditing: PropTypes.bool
};

export default ModifyEntryModal;
