import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import classes from './IpGeolocationModal.module.css';

const IpGeolocationModal = props => <Modal
  title={ props.ipAddressRange + ' Geolocation' }
  actions={
    <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
  }
  hideModal={ props.hideModal }>
  <table className={ classes.table }>
    <tr>
      <th className={ classes.tableCell }>Registered For</th>
      <td className={ classes.tableCell }>{ props.geolocationData.networkOrganization }</td>
    </tr>
    <tr>
      <th className={ classes.tableCell }>Country</th>
      <td className={ classes.tableCell }>{ props.geolocationData.isoName }</td>
    </tr>
    <tr>
      <th className={ classes.tableCell }>ISO ALPHA-3</th>
      <td className={ classes.tableCell }>{ props.geolocationData.isoAlpha3 }</td>
    </tr>
    <tr>
      <th className={ classes.tableCell }>City</th>
      <td className={ classes.tableCell }>{ props.geolocationData.city }</td>
    </tr>
    <tr>
      <th className={ classes.tableCell }>Carrier</th>
      <td className={ classes.tableCell }>{ props.geolocationData.carrier }</td>
    </tr>
    <tr>
      <th className={ classes.tableCell }>ASN</th>
      <td className={ classes.tableCell }>{ props.geolocationData.asn }</td>
    </tr>
  </table>
</Modal>;

IpGeolocationModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  geolocationData: PropTypes.object.isRequired,
  ipAddressRange: PropTypes.array.isRequired,
};

export default IpGeolocationModal;
