import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import eventsReducer from './reducers/events';
import userReducer from './reducers/user';
import titleReducer from './reducers/title';
import { ENV_DEV } from '../env';

const rootReducer = combineReducers({
  events: eventsReducer,
  user: userReducer,
  title: titleReducer
});

const composeEnhancers = process.env.REACT_APP_ENV === ENV_DEV
  ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;

export default createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
