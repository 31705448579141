import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import { scaleLinear } from 'd3-scale';
import PropTypes from 'prop-types';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import geo from './world-110m.json';
import classes from './MapChart.module.css';

const MapChart = props => {
  const [ position, setPosition ] = useState({ coordinates: [ 0, 0 ], zoom: 1 });
  const [ tooltip, setTooltipContent ] = useState('');
  const minValue = props.minValue || 0;
  const maxValue = props.maxValue || 100;
  const coldColor = props.coldColor || '#ffedea';
  const hotColor = props.hotColor || '#ff5233';
  const naColor = '#dadfe8';

  const handleZoomIn = () => {
    if (position.zoom >= 4) {
      return;
    }

    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) {
      return;
    }

    setPosition(pos => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const handleMoveEnd = position => {
    setPosition(position);
  };

  const colorScale = scaleLinear()
    .domain([ minValue, maxValue ])
    .range([ coldColor, hotColor ]);

  return <>
    <div className={ classes.mapContainer }>
      <ComposableMap data-tip="">
        <ZoomableGroup
          zoom={ position.zoom }
          center={ position.coordinates }
          onMoveEnd={ handleMoveEnd }>
          <Geographies geography={ geo }>
            { ({ geographies }) =>
              geographies.map(geography => {
                const data = props.data[geography.properties.ISO_A3];

                return (
                  <Geography
                    key={ geography.rsmKey }
                    geography={ geography }
                    fill={ data ? colorScale(data.chartValue) : naColor }
                    outline="none"
                    onMouseEnter={ () => {
                      setTooltipContent(`${ data ? data.chartValue.toLocaleString() : 0 } IPs from ${ geography.properties.NAME }`);
                    } }
                    onMouseLeave={ () => {
                      setTooltipContent('');
                    } }
                    style={ {
                      default: {
                        outline: 'none'
                      },
                      hover: {
                        fill: '#a0d1dc',
                        outline: 'none'
                      },
                      pressed: {
                        outline: 'none'
                      }
                    } }
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div className={ classes.zoomControls }>
        <Button className={ classes.zoomControl } size='sm' variant='secondary' onClick={ handleZoomIn }>
          <FontAwesomeIcon icon={ faPlus }/>
        </Button>
        <Button className={ classes.zoomControl } size='sm' variant='secondary' onClick={ handleZoomOut }>
          <FontAwesomeIcon icon={ faMinus }/>
        </Button>
      </div>
      {
        props.hideLegend
          ? null
          : <div className={ classes.legend }>
            <div className={ classes.legendDynamic }>
              <div className={ classes.legendLabel }>
                <div>{ minValue.toLocaleString() }</div>
                <div>{ maxValue.toLocaleString() }</div>
              </div>
              <div className={ classes.legendScale } style={ {
                background: 'linear-gradient(90deg, ' + coldColor + ' 0%, ' + hotColor + ' 100%)'
              } }/>
            </div>
            <div className={ classes.legendStatic }>
              <div>Other</div>
              <div className={ classes.legendColorStatic } style={ { backgroundColor: naColor } }/>
            </div>
          </div>
      }
    </div>
    <ReactTooltip>{ tooltip }</ReactTooltip>
  </>;
};

MapChart.propTypes = {
  data: PropTypes.object.isRequired,
  hideLegend: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  coldColor: PropTypes.string,
  hotColor: PropTypes.string
};

export default React.memo(MapChart);
