import { Jumbotron } from 'react-bootstrap';
import React from 'react';

import classes from './BannedView.module.css';

const BannedView = () => <Jumbotron className={ classes.wrapper }>
  <h3 className={ classes.header }>Your account is banned</h3>
</Jumbotron>;

export default BannedView;
