import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import classes from './TopPanel.module.css';

const TopPanel = props => <Row className={ classes.topActionsRow } noGutters>
  <Col xs={ true }/>
  <Col xs="auto">
    <Button
      id="configuration__add-button"
      size="sm"
      variant="success"
      className={ classes.topAction }
      onClick={ props.onNewNetworkElement }>
      <FontAwesomeIcon icon={ faPlus }/> Add New
    </Button>
  </Col>
  <Col xs="auto">
    <Button
      size="sm"
      variant="primary"
      onClick={ props.onBulkUpload }
      className={ classes.topAction }>
      <FontAwesomeIcon icon={ faUpload }/> Bulk Upload
    </Button>
  </Col>
  <Col xs="auto">
    <Button
      size="sm"
      variant="primary"
      onClick={ props.onBulkUpdate }
      className={ classes.topAction }>
      <FontAwesomeIcon icon={ faEdit }/> Bulk Update
    </Button>
  </Col>
</Row>;

TopPanel.propTypes = {
  onNewNetworkElement: PropTypes.func.isRequired,
  onBulkUpload: PropTypes.func.isRequired,
  onBulkUpdate: PropTypes.func.isRequired
};

export default TopPanel;
