import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import classes from './ResultsExportModal.module.css';

class ResultsExportModal extends Component {
  state = {
    combineToOneFile: false,
    selectedSummaries: [],
    selectedResultTypes: [],
    allSummariesSelected: false
  };

  toggle(key, currentId) {
    const stateItem = {};
    stateItem[key] = [ ...this.state[key] ];
    const index = this.state[key].findIndex(id => id === currentId);
    if (index === -1) {
      stateItem[key].push(currentId);
    } else {
      delete stateItem[key][index];
      stateItem[key] = stateItem[key].filter(_ => _);
    }

    if (key === 'selectedSummaries' && stateItem[key].length !== this.state[key].length) {
      stateItem['selectedResultTypes'] = [];
    }

    let allSummariesSelected = this.state.allSummariesSelected;
    if (key === 'selectedSummaries') {
      allSummariesSelected = stateItem[key].length === this.props.networkElements.length;
    }

    this.setState({ ...stateItem, allSummariesSelected });
  }

  selectAllSummaries() {
    if (this.state.allSummariesSelected) {
      this.setState({ allSummariesSelected: false, selectedSummaries: [], selectedResultTypes: [] });
      return;
    }

    this.setState({
      allSummariesSelected: true,
      selectedSummaries: this.props.networkElements.map(ne => ne.summaryId)
    });
  }

  toggleCombine() {
    this.setState({ combineToOneFile: !this.state.combineToOneFile });
  }

  render() {
    const nonZeroNe =  this.props.networkElements.filter(ne => ne.nonZeroResults);
    const results = {};
    nonZeroNe.forEach(ne => {
      if (this.state.selectedSummaries.indexOf(ne.summaryId) !== -1) {
        const { id, configuration_id, created_at, updated_at, ...resultTypeNames } = ne;
        Object.entries(resultTypeNames).forEach(([ name, value ]) => {
          if (!results[name]) {
            results[name] = value;
          } else {
            results[name] += value;
          }
        });
      }
    });

    return <Modal
      actions={
        <Container>
          <Row>
            <Col>
              {
                this.state.selectedSummaries.length > 1
                  ? <Form>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        checked={ this.state.combineToOneFile }
                        onChange={ () => this.toggleCombine() }
                        label="combine to one xls file"/>
                    </Form.Group>
                  </Form>
                  : null
              }
            </Col>
            <Col className={ classes.actionsContainer }>
              <Button
                variant="secondary"
                onClick={ () => this.props.hideModal() }>
                Close
              </Button>
              <Button
                variant="primary"
                disabled={ this.state.selectedSummaries.length === 0 || this.state.selectedResultTypes.length === 0 }
                onClick={ () => this.props.submitActionHandler(this.state.selectedSummaries, this.state.selectedResultTypes, this.state.combineToOneFile) }>Export</Button>
            </Col>
          </Row>
        </Container>
      }
      hideModal={ this.props.hideModal }
      title={ `Export ${ [ this.props.vendorName, this.props.typeName, this.props.subtypeName ].join(' ') } audits` }>
      <Form>
        <Form.Group>
          <h5>Select audits to export:</h5>
          {
            nonZeroNe.length > 1
              ? <Form.Check>
                <Form.Check.Label className={ classes.selectAllCheckbox }>
                  <Form.Check.Input
                    type="checkbox"
                    className={ classes.checkbox }
                    checked={ this.state.allSummariesSelected }
                    onChange={ this.selectAllSummaries.bind(this) }/>
                  Select All
                </Form.Check.Label>
              </Form.Check>
              : null
          }
          {
            nonZeroNe.map(ne =>
              <Form.Check>
                <Form.Check.Label>
                  <Form.Check.Input
                    type="checkbox"
                    className={ classes.checkbox }
                    checked={ this.state.selectedSummaries.indexOf(ne.summaryId) !== -1 }
                    onChange={ () => this.toggle('selectedSummaries', ne.summaryId) }/>
                  { ne.name }
                </Form.Check.Label>
              </Form.Check>
            )
          }
        </Form.Group>
        {
          this.state.selectedSummaries.length > 0
            ? <Form.Group>
              <h5>Select audit result types:</h5>
              {
                Object.entries(this.props.resultTypes)
                  .filter(([ _id, name ]) => results[name.toLowerCase()] > 0)
                  .map(([ id, name ]) =>
                    <Form.Check>
                      <Form.Check.Label>
                        <Form.Check.Input
                          type="checkbox"
                          className={ classes.checkbox }
                          checked={ this.state.selectedResultTypes.indexOf(id) !== -1 }
                          onChange={ () => this.toggle('selectedResultTypes', id) }/>
                        { name + ' (' + results[name.toLowerCase()] + ')' }
                      </Form.Check.Label>
                    </Form.Check>
                  )
              }
            </Form.Group>
            : null
        }
      </Form>
    </Modal>;
  };
}

ResultsExportModal.propTypes = {
  vendorName: PropTypes.string.isRequired,
  typeName: PropTypes.string.isRequired,
  subtypeName: PropTypes.string.isRequired,
  networkElements: PropTypes.array.isRequired,
  resultTypes: PropTypes.array.isRequired,
  hideModal: PropTypes.func.isRequired,
  submitActionHandler: PropTypes.func.isRequired
};

export default ResultsExportModal;
