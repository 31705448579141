import React from 'react';
import { LiteTable } from 'react-lite-table';

const UnwantedResultTable = props => <LiteTable
  rows={ props.rows }
  onFilter={ props.onFilter }
  columnsMap={ { 'ns': 'NS' } }
  withSorting
  withFiltering/>;

export default UnwantedResultTable;
