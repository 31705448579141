import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import classes from './Key2RoamModal.module.css';

export class Key2RoamEnum {
  static NORMAL = 'NORMAL';
  static EXCLUDE_KEY2ROAM = 'EXCLUDE_KEY2ROAM';
  static REPLACE = 'REPLACE';
  static EXCLUDE_TADIGS = 'EXCLUDE_TADIGS';
}

export class Key2RoamSupportedSubtypeEnum {
  static ASN = 'ASN';
  static IP_RANGE = 'IP_RANGE';
  static GPRS_DNS = 'GPRS_DNS';
  static LTE_DNS = 'LTE_DNS';
}

class Key2RoamModal extends Component {
  state = {
    selectedOption: Key2RoamEnum.NORMAL,
    inputEntries: []
  };

  regexps = {
    ASN: /^[0-9]+$/,
    IP_RANGE: /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\/(?:1[6-9]+|2[0-9]|3[0-2])$/
  };

  processInput(event) {
    const content = event.target.value;
    const inputEntries = [];

    content.split('\n').map((line) => {
      if (
        this.regexps[this.props.variation].test(line)
        && inputEntries.indexOf(line) === -1
      ) {
        inputEntries.push(line);
      }
    });

    if (JSON.stringify(inputEntries) !== JSON.stringify(this.state.inputEntries)) {
      this.setState({ inputEntries });
    }
  }

  processTadigsInput(event) {
    const content = event.target.value;
    const inputEntries = [];

    content.split('\n').map((line) => {
      if (
        /^[0-9a-z]{5}$/i.test(line)
        && inputEntries.indexOf(line) === -1
      ) {
        inputEntries.push(line);
      }
    });

    if (JSON.stringify(inputEntries) !== JSON.stringify(this.state.inputEntries)) {
      this.setState({ inputEntries });
    }
  }

  handleOptionChange(event) {
    this.setState({ selectedOption: event.target.value, inputEntries: [] });
  }

  render() {
    return <Modal
      actions={
        <>
          <Button variant="secondary" onClick={ this.props.hideModal }>Close</Button>
          <Button variant="primary"
                  id="key2RoamRunAuditButton"
                  onClick={
                    () => this.props.onConfirm(this.state.selectedOption, this.state.inputEntries)
                  }>
            Run Audit
          </Button>
        </>
      }
      hideModal={ this.props.hideModal }
      title="Choose an option to continue">
      <div className={ 'Key2Roam__option ' + classes.option }>
        <label>
          <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                 value={ Key2RoamEnum.NORMAL } checked={ this.state.selectedOption === Key2RoamEnum.NORMAL }/>
          <span className={ classes.label }>Continue</span>
        </label>
      </div>
      {
        this.props.hasKey2Roam ? <>
          <div className={ 'Key2Roam__option ' + classes.option }>
            <label>
              <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                     value={ Key2RoamEnum.EXCLUDE_KEY2ROAM }
                     checked={ this.state.selectedOption === Key2RoamEnum.EXCLUDE_KEY2ROAM }/>
              <span className={ classes.label }>Exclude Key2Roam</span>
            </label>
          </div>
          <div className={ 'Key2Roam__option ' + classes.option }>
            <label>
              <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                     value={ Key2RoamEnum.REPLACE }
                     checked={ this.state.selectedOption === Key2RoamEnum.REPLACE }/>
              <span className={ classes.label }>Replace Key2Roam with a list of { this.props.variation }s</span>
            </label>
            {
              this.state.selectedOption === Key2RoamEnum.REPLACE
                ? <>
                  <textarea type="text"
                            onChange={ this.processInput.bind(this) }
                            className={ classes.textarea }></textarea>
                  <div>{ this.state.inputEntries.length } Unique { this.props.variation }s</div>
                </>
                : null
            }
          </div>
        </> : null
      }
      <div className={ 'Key2Roam__option ' + classes.option }>
        <label>
          <input type="radio" name="flow" onChange={ this.handleOptionChange.bind(this) }
                 value={ Key2RoamEnum.EXCLUDE_TADIGS }
                 checked={ this.state.selectedOption === Key2RoamEnum.EXCLUDE_TADIGS }/>
          <span className={ classes.label }>Exclude list of TADIGs</span>
        </label>
        {
          this.state.selectedOption === Key2RoamEnum.EXCLUDE_TADIGS
            ? <>
                  <textarea type="text"
                            onChange={ this.processTadigsInput.bind(this) }
                            className={ classes.textarea }></textarea>
              <div>{ this.state.inputEntries.length } Unique TADIGs</div>
            </>
            : null
        }
      </div>
    </Modal>
  }
}

Key2RoamModal.propTypes = {
  hasKey2Roam: PropTypes.bool,
  variation: PropTypes.objectOf(Key2RoamSupportedSubtypeEnum),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default Key2RoamModal;
