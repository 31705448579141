import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import UpdateImsiTable from './UpdateImsiTable/UpdateImsiTable';

const UpdateImsiModal = props => {
  const [isRemovalAllowed, setIsRemovalAllowed] = useState(false);

  return <Modal
    size="sm"
    actions={
      <>
        <Button variant="secondary" onClick={ props.hideModal }>Close</Button>
        <Button
          variant="primary"
          onClick={ () => props.submitActionHandler(props.entry) }
          disabled={ !isRemovalAllowed }>
          Remove
        </Button>
      </>
    }
    hideModal={ props.hideModal }
    title={ `${props.entry.networkElement} (${props.entry.vendorName}): ${props.entry.total} Entries` }>
    <UpdateImsiTable
      columns={ props.entry.columns }
      rows={ props.entry.rows }
      checkboxesHandler={ checked => {
        setIsRemovalAllowed(checked.length > 0);
        props.checkboxesHandler(checked);
      } }
      reviewDetails={ row => props.reviewDetailsHandler(props.entry, row.valueLength) }/>
  </Modal>;
};

UpdateImsiModal.propTypes = {
  entry: PropTypes.object.isRequired,
  checkboxesHandler: PropTypes.func.isRequired,
  analyzeActionHandler: PropTypes.func.isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  reviewDetailsHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isRemovalAllowed: PropTypes.bool.isRequired
};

export default UpdateImsiModal;
