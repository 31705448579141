import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withModals, withNotifications } from 'react-ui-utils';

import ManualCheckModal from './ManualCheckModal/ManualCheckModal';
import RowAnalysisModal from './RowAnalysisModal/RowAnalysisModal';
import axios from '../../services/axios';

class RowAnalysis extends Component {
  state = {
    nsAnalysis: null,
    nsAnalysisInitialValue: null,
  }

  componentDidMount() {
    this.analyzeRow();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.row !== prevProps.row) {
      this.analyzeRow();
    }
  }

  async showFullAnalysis(checkInfo, ns) {
    if (!ns || String(ns).length === 0) {
      return;
    }

    try {
      const analysisResponse = await axios.get(this.props.url + '/' + ns);

      this.props.showModal(RowAnalysisModal, {
        rowAnalysisEntries: analysisResponse.data,
        checkEntry: this.props.row,
        checkInfo,
        nsFilterInitialValue: ns,
        onNsFilterChange: this.showFullAnalysis.bind(this, checkInfo)
      }, this.props.onHideCallback);
    } catch (e) {
      this.props.processError(e);
    }
  }

  async analyzeRow() {
    try {
      const manualCheckResponse = await axios.get('/elements/info/' + this.props.row.ir21_id);

      if (this.props.showFullAnalysis) {
        const ns = this.props.row.ns || this.props.row.mgt || this.props.row.gt;

        await this.showFullAnalysis(manualCheckResponse.data, ns);
      } else {
        this.props.showModal(ManualCheckModal, {
          entry: this.props.row,
          checkInfo: manualCheckResponse.data
        }, this.props.onHideCallback);
      }
    } catch (e) {
      this.props.processError(e);
    }
  }

  render() {
    return null;
  }
}

RowAnalysis.propTypes = {
  row: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  showFullAnalysis: PropTypes.bool,
  onHideCallback: PropTypes.func.isRequired
};

export default withNotifications(withModals(RowAnalysis));
