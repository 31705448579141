import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';

import DropdownSearch from '../DropdownSearch/DropdownSearch';

import classes from './DynamicField.module.css';

const DynamicField = props => {
  const [ dynamicFields, setDynamicFields ] = useState([]);
  const [ availableFields, setAvailableFields ] = useState(props.availableFields.filter(field => dynamicFields.indexOf(field) === -1));
  const [ selectedField, setSelectedField ] = useState(availableFields[0]);

  useEffect(() => {
    if (props.formik.values[props.fieldName]) {
      setDynamicFields(Object.keys(props.formik.values[props.fieldName]));
    }
  }, [])

  useEffect(() => {
    const availableFields = props.availableFields.filter(field => dynamicFields.indexOf(field) === -1);
    setAvailableFields(availableFields);
    if (availableFields.length > 0) {
      setSelectedField(availableFields[0]);
    }
  }, [ dynamicFields ]);

  const fieldSelectionHandler = field => {
    setSelectedField(field);
  };

  const addField = () => {
    setDynamicFields([ ...dynamicFields, selectedField ]);
  };

  const removeField = field => {
    const newDynamicFields = [ ...dynamicFields ];
    delete newDynamicFields[newDynamicFields.indexOf(field)];

    setDynamicFields(newDynamicFields.filter(_ => _ !== undefined));
  };

  return <div>
    <h4 className={ classes.dynamicFieldName }>{ props.fieldName }</h4>
    {
      availableFields.length > 0
        ?
        <div className={ classes.addField }>
          <div className={ classes.addFieldDropdown }>
            <DropdownSearch
              current={ selectedField }
              changed={ fieldSelectionHandler }
              list={ availableFields }
              uniquePrefix={ 'DynamicField__' + props.formName }/>
          </div>
          <Button
            variant="primary"
            title="Add Field"
            size="sm"
            className={ classes.addFieldButton }
            onClick={ addField }>
            <FontAwesomeIcon icon={ faPlus }/>
          </Button>
        </div>
        : null
    }
    <div className={ classes.fieldsContainer }>
      {
        dynamicFields.map(
          field => <Form.Group
            controlId={ 'form-' + field }
            className={ classes.dynamicField }>
            <Form.Label className={ classes.label }>{ field }</Form.Label>
            <div className={ classes.input }>
              <Form.Control
                className={ classes.dynamicFieldInput }
                type="text"
                name={ props.fieldName + '.' + field }
                value={ props.formik.values[props.fieldName][field] }
                onChange={ props.formik.handleChange }
                placeholder={ field }/>
              <span className={ classes.removeField }
                    onClick={ () => removeField(field) }>
              &times;
            </span>
            </div>
          </Form.Group>
        )
      }
    </div>
  </div>
};

DynamicField.propTypes = {
  formName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  availableFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentEntry: PropTypes.object.isRequired,
};

export default DynamicField;
