import React, { createRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import classes from './DropdownSearch.module.css';

const DropdownSearch = props => {
  const [ needle, setNeedle ] = useState('');
  const toggleTitleRef = createRef();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button className={ classes.toggleButton }
            variant="outline-secondary"
            ref={ ref }
            id={ props.uniquePrefix }
            onClick={ e => {
              e.preventDefault();
              onClick(e);
            } }>
      <div ref={ toggleTitleRef }>
        { props.current ? (Array.isArray(props.list) ? props.current : props.current.name) : (props.emptyDropdownTitle || '') }
      </div>
      <FontAwesomeIcon className={ classes.chevronIcon } icon={ faChevronDown }/>
    </Button>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ ref }
          style={ style }
          className={ className }
          aria-labelledby={ labeledBy }
        >
          <div className={ classes.searchWrapper }>
            <FormControl
              autoFocus
              className={ classes.searchInput }
              placeholder="Type to filter..."
              onChange={ e => setNeedle(e.target.value) }
              value={ needle }
            />
            {
              needle
                ? <FontAwesomeIcon
                  onClick={ () => setNeedle('') }
                  className={ classes.clearInput }
                  icon={ faTimes }/>
                : null
            }
          </div>
          <ul className={`${ classes.listOverflow } list-unstyled`}>
            {
              React.Children.toArray(children).filter(
                child => !needle || child.props.children.toLowerCase().startsWith(needle.toLowerCase()),
              )
            }
          </ul>
        </div>
      );
    },
  );

  let itemsList;
  if (Array.isArray(props.list)) {
    itemsList = props.list.map(entry =>
      <Dropdown.Item
        id={ props.uniquePrefix + '--' + entry }
        active={ props.current ? props.current === entry : false }
        eventKey={ entry }>
        { entry }
      </Dropdown.Item>
    );
  } else if (Array.isArray(props.dictionary)) {
    itemsList = props.dictionary.map(obj =>
      <Dropdown.Item
        id={ props.uniquePrefix + '--' + obj.name }
        active={ props.current ? props.current.id.toString() === String(obj.id) : false }
        eventKey={ JSON.stringify({ id: obj.id, name: obj.name }) }>
        { obj.name }
      </Dropdown.Item>
    );
  } else if (props.dictionary && typeof props.dictionary === 'object') {
    itemsList = Object.entries(props.dictionary)
      .map(
        ([ id, obj ]) =>
          <Dropdown.Item
            id={ props.uniquePrefix + '--' + obj.name }
            active={ props.current ? props.current.id.toString() === String(obj.id) : false }
            eventKey={ JSON.stringify({ id: obj.id, name: obj.name }) }>
            { obj.name }
          </Dropdown.Item>
      );
  } else {
    return null;
  }

  return <Dropdown onSelect={ (eventKey, event) => {
    toggleTitleRef.current.innerText = event.target.innerText;
    props.changed(Array.isArray(props.list) ? eventKey : JSON.parse(eventKey));
  } }>
    <Dropdown.Toggle as={ CustomToggle }/>
    <Dropdown.Menu as={ CustomMenu }>
      { itemsList }
    </Dropdown.Menu>
  </Dropdown>;
}

DropdownSearch.propTypes = {
  changed: PropTypes.func.isRequired,
  uniquePrefix: PropTypes.string.isRequired,
  current: PropTypes.object,
  dictionary: PropTypes.oneOfType([ PropTypes.object, PropTypes.arrayOf(PropTypes.object) ]),
  list: PropTypes.arrayOf(PropTypes.string),
  emptyDropdownTitle: PropTypes.string
}

export default DropdownSearch;
