import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import RawSwitch from '../RawSwitch/RawSwitch';

const GroupSwitch = props => {
  const isAllChecked = () => {
    return props.menus.filter(val => props.formik.values.blockedMenus.includes(val)).length === 0;
  };

  const toggleAll = () => {
    if (isAllChecked()) {
      props.formik.setFieldValue('blockedMenus', [ ...props.formik.values.blockedMenus, ...props.menus ]);
      return;
    }

    const blockedMenus = props.formik.values.blockedMenus.filter(menu => !props.menus.includes(menu));

    props.formik.setFieldValue('blockedMenus',  blockedMenus);
  };

  return <RawSwitch id={ props.id } className="pb-0 pt-4" isChecked={ isAllChecked() } label={ props.label } onChange={ toggleAll }/>;
};

GroupSwitch.propTypes = {
  menus: PropTypes.array.isRequired,
  formik: PropTypes.objectOf(Formik).isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default GroupSwitch;
