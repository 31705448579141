import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faList, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { AlignmentEnum, LiteTable } from 'react-lite-table';

const ConfigurationsTable = props => {
  const actions = row => {
    const actions = [];

    if ((row.subtypeName === 'IMSI' || row.subtypeName === 'IMSI_ANSI') && row.entries > 0) {
      actions.push(
        <Button
          variant="warning"
          size="sm"
          data-kind="edit-imsi-configurations"
          title="Edit IMSI Configuration"
          onClick={ e => {
            e.stopPropagation();

            props.updateImsiConfiguration(row.id);
          } }>
          <FontAwesomeIcon icon={ faEdit }/>
        </Button>
      );
    }

    actions.push(
      <Button
        variant="primary"
        size="sm"
        disabled={ row.fileName.length === 0 }
        data-kind="list-configurations"
        title="List Configurations"
        onClick={ e => {
          e.stopPropagation();

          if (row.fileName.length > 0) {
            props.listConfigurations(row);
          }
        } }>
        <FontAwesomeIcon icon={ faList }/>
      </Button>
    );

    actions.push(
      <Button
        id={ 'configuration-table__action--' + row.name }
        variant="primary"
        size="sm"
        data-kind="upload-configuration"
        title="Upload Configuration"
        onClick={ e => {
          e.stopPropagation();

          props.uploadConfiguration(row);
        } }>
        <FontAwesomeIcon icon={ faUpload }/>
      </Button>
    );

    actions.push(
      <Button
        variant="danger"
        size="sm"
        data-kind="remove-network-element"
        title="Remove Network Element"
        onClick={ e => {
          e.stopPropagation();

          props.removeNetworkElement(row);
        } }>
        <FontAwesomeIcon icon={ faTrashAlt }/>
      </Button>
    );

    return actions;
  };

  return <LiteTable
    rows={ props.rows }
    actions={ actions }
    tableId="configuration-table"
    columnsMap={ {
      'name': 'Node Name',
      'vendorName': 'Vendor',
      'subtypeName': 'Subtype',
      'updatedAt': 'Last Update',
      'fileName': 'File Name',
      'uploadedBy': 'Uploaded By',
      'entries': 'Entries',
      'updates': 'Updates'
    } }
    actionsAlignment={ AlignmentEnum.RIGHT }
    withSorting
    withFiltering
    cellValueResolver={ (row, column) => {
      if ((column === 'uploadedBy' || column === 'fileName') && row[column]) {
        return row[column].join("\n");
      }

      return row[column];
    } }/>
};

ConfigurationsTable.propTypes = {
  updateImsiConfiguration: PropTypes.func.isRequired,
  removeNetworkElement: PropTypes.func.isRequired,
  uploadConfiguration: PropTypes.func.isRequired,
  listConfigurations: PropTypes.func.isRequired
};

export default ConfigurationsTable;
