import worker from '../pollingWorker';

export default class PollingWorker {
  constructor() {
    const code = worker.toString();
    const blob = new Blob(['('+code+')()']);

    return new Worker(URL.createObjectURL(blob));
  }
}
