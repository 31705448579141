import PropTypes from 'prop-types';
import { Formik } from 'formik';
import React from 'react';

import RawSwitch from '../RawSwitch/RawSwitch';

const Switch = props => {
  const toggle = () => {
    if (!props.formik.values.blockedMenus.includes(props.label)) {
      const blockedMenus = [ ...props.formik.values.blockedMenus ];
      blockedMenus.push(props.label);
      props.formik.setFieldValue('blockedMenus', blockedMenus);
      return;
    }

    const blockedMenus = props.formik.values.blockedMenus.filter(menu => menu !== props.label);
    props.formik.setFieldValue('blockedMenus', blockedMenus);
  }

  return <RawSwitch
    label={ props.label }
    isChecked={ !props.formik.values.blockedMenus.includes(props.label) }
    onChange={ toggle }
    id={ props.id }/>
}

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  formik: PropTypes.objectOf(Formik).isRequired,
  id: PropTypes.number.isRequired
};

export default Switch;
