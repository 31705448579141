import React from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import { ALL, BOTH, CURRENT } from '../ExceptionRemovalModeEnum';
import { Modal } from 'react-ui-utils';

const RemoveExceptionModal = props => {
  let body;

  if (props.currentExceptions.length > 1) {
    body = <>
      <p>
        Are you sure you want to remove this exception?
      </p>
      <p>
        You have several options, please select if you want to remove it only for this Network
        Element or for all Network Elements with the same vendor-type-subtype combination or both of
        them?
      </p>
      <p>
        Please, note that you have to re-run related audits after this action to get a relevant
        results representation.
      </p>
    </>;
  } else if (props.currentExceptions.length === 1) {
    body = <>
      <p>
        Are you sure you want to remove this exception?
      </p>
      <p>
        {
          props.isGlobal
            ? 'Please pay an attention! It will have effect on all Network Elements with the same vendor-type-subtype combination.'
            : 'It will have effect only on this Network Element.'
        }
      </p>
      <p>
        Please, note that you have to re-run related audits after this action to get a relevant
        results representation.
      </p>
    </>;
  } else {
    body = <p>
      No exceptions were found for such entry. Please try to re-run an audit.
    </p>;
  }

  return <Modal
    id="ResultsView__remove-exception-modal"
    actions={
      <>
        <Button variant="secondary" onClick={ () => props.hideModal() }>Cancel</Button>
        {
          props.currentExceptions.length === 1
            ? <Button
              variant="primary"
              onClick={ () => props.submitActionHandler(props.isGlobal ? ALL : CURRENT) }>
              Yes, I am sure
            </Button>
            : <>
              <Button
                variant="primary"
                onClick={ () => props.submitActionHandler(BOTH) }>
                Remove both
              </Button>
              <Button
                variant="primary"
                onClick={ () => props.submitActionHandler(ALL) }>
                Remove for all NE
              </Button>
              <Button
                variant="primary"
                onClick={ () => props.submitActionHandler(CURRENT) }>
                Remove only for this NE
              </Button>
            </>
        }
      </>
    }
    size="lg"
    hideModal={ props.hideModal }
    title="Remove an entry from the exceptions">
    { body }
  </Modal>;
};

RemoveExceptionModal.propTypes = {
  isGlobal: PropTypes.bool,
  currentExceptions: PropTypes.array.isRequired,
  submitActionHandler: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default RemoveExceptionModal;
