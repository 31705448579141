import { Jumbotron } from 'react-bootstrap';
import React from 'react';

import classes from './TemporaryDeactivatedView.module.css';

const TemporaryDeactivatedView = () => <Jumbotron className={ classes.wrapper }>
  <h3 className={ classes.header }>Temporarily Deactivated</h3>
  <div>Please Contact Your Administrator</div>
</Jumbotron>;

export default TemporaryDeactivatedView;
