import React from 'react';
import { LiteTable } from 'react-lite-table';

const ConfigurationFilesTable = props => <LiteTable
  columns={ props.columns }
  cellWrapper={ (row, column, value) => {
    if (column === 'file') {
      return <a target="_blank" href={ '/configuration/files/' + row.id + '/' + row.file }>
        { value }
      </a>
    }

    if (column === 'matched') {
      return <a target="_blank" href={ '/configuration/files/' + row.id + '/matches/' + row.file }>
        { value }
      </a>
    }

    if (column === 'mismatched') {
      return <a target="_blank" href={ '/configuration/files/' + row.id + '/mismatches/' + row.file }>
        { value }
      </a>
    }

    return value;
  } }
  rows={ props.rows }
  columnsMap={ {
    'file': 'Uploaded File',
    'lines': 'Lines',
    'matched': 'Matched',
    'mismatched': 'Mismatched',
    'uploadDate': 'Upload Date',
    'uploadedBy': 'Uploaded By'
  } }/>;

export default ConfigurationFilesTable;
