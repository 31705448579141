import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Row } from 'react-bootstrap';

import axios from '../../services/axios';
import DropdownSearch from '../DropdownSearch/DropdownSearch';

import classes from './ElementSelector.module.css';
import ElementTotals from './ElementTotals/ElementTotals';

class ElementSelector extends Component {
  state = {
    elements: {},
    currentFirst: null,
    currentSecond: null,
    currentThird: null,
  };

  componentDidMount() {
    this.loadAvailableElements();
  }

  selectFirst(currentFirst) {
    const secondIds = Object.keys(this.state.elements[currentFirst.id].data);
    if (secondIds.length > 0) {
      this.selectSecond(currentFirst, { ...this.state.elements[currentFirst.id].data[secondIds[0]] });
    }
  }

  selectSecond(currentFirst, currentSecond) {
    const thirdIds = Object.keys(this.state.elements[currentFirst.id].data[currentSecond.id].data);
    if (thirdIds.length > 0) {
      if (this.props.lazy) {
        this.setState({ currentThird: null, currentFirst, currentSecond });
        this.props.elementChanged(null, currentSecond, currentFirst);
      } else {
        this.selectThird(
          currentFirst,
          currentSecond,
          {
            ...this.state.elements[currentFirst.id].data[currentSecond.id]
              .data[thirdIds[0]]
          }
        );
      }
    }
  }

  selectThird(currentFirst, currentSecond, currentThird) {
    this.setState({ currentFirst, currentSecond, currentThird });
    this.props.elementChanged(currentThird, currentSecond, currentFirst);
  }

  onSecondChange(newSecond) {
    this.selectSecond(this.state.currentFirst, newSecond);
  }

  onThirdChange(newThird) {
    this.selectThird(this.state.currentFirst, this.state.currentSecond, newThird);
  }

  async loadAvailableElements() {
    const response = await axios.get(this.props.elementsUrl);

    if (response.data.length === 0) {
      this.setState({ elements: {} });
      return;
    }

    const elements = this.restructureElements(response.data);

    this.setState({ elements }, () => {
      if (this.tryToInit(elements)) {
        return;
      }

      let first = null;
      const keys = Object.keys(elements);
      if (keys.length > 0) {
        first = elements[keys[0]];
        this.selectFirst(first);
      }
    });
  }

  tryToInit(elements) {
    if (
      elements[this.props.initFirst]
      && elements[this.props.initFirst].data[this.props.initSecond]
      && elements[this.props.initFirst].data[this.props.initSecond].data[this.props.initThird]
    ) {
      const currentFirst = elements[this.props.initFirst];
      const currentSecond = currentFirst.data[this.props.initSecond];
      const currentThird = currentSecond.data[this.props.initThird];

      this.setState({ currentFirst, currentSecond, currentThird });
      this.props.elementChanged(currentThird, currentSecond, currentFirst);

      return true;
    }

    return false;
  }

  restructureElements(newElements) {
    const tree = {};

    newElements.map((element) => {
      if (!tree[element[this.props.firstName].id]) {
        tree[element[this.props.firstName].id] = {
          id: element[this.props.firstName].id,
          name: element[this.props.firstName].name,
          data: {}
        };
      }

      if (!tree[element[this.props.firstName].id].data[element[this.props.secondName].id]) {
        tree[element[this.props.firstName].id].data[element[this.props.secondName].id] = {
          id: element[this.props.secondName].id,
          name: element[this.props.secondName].name,
          data: {}
        };
      }

      if (!tree[element[this.props.firstName].id].data[element[this.props.secondName].id].data[element[this.props.thirdName].id]) {
        tree[element[this.props.firstName].id].data[element[this.props.secondName].id].data[element[this.props.thirdName].id] = {
          id: element[this.props.thirdName].id,
          name: element[this.props.thirdName].name
        };
      }
    });

    return tree;
  }

  render() {
    const secondLayerItems = this.state.currentFirst ? this.state.elements[this.state.currentFirst.id].data : {};

    if (Object.keys(this.state.elements).length === 0) {
      return <>
        <Row>
          <Col xs="12">
            <Alert variant="warning">
              No supported Network Elements were found.
            </Alert>
          </Col>
        </Row>
      </>;
    }

    return <>
      <Row className={ classes.dropdownControlsPanel }>
        {
          Object.keys(this.state.elements).length <= 1 && this.props.hideNoChoice
            ? null
            : <Col xs={ this.props.size || 3 } className={ classes.selectorColumn }>
              <DropdownSearch
                changed={ this.selectFirst.bind(this) }
                uniquePrefix="ElementSelector__first-selectbox"
                current={ this.state.currentFirst }
                dictionary={ this.state.elements }/>
            </Col>
        }
        {
          Object.keys(secondLayerItems).length <= 1 && this.props.hideNoChoice
            ? null
            : <Col xs={ this.props.size || 3 } className={ classes.selectorColumn }>
              <DropdownSearch
                changed={ this.onSecondChange.bind(this) }
                uniquePrefix="ElementSelector__second-selectbox"
                current={ this.state.currentSecond }
                dictionary={ secondLayerItems }/>
            </Col>
        }
        {
          <Col xs={ this.props.size || 3 } className={ classes.selectorColumn }>
            <DropdownSearch
              changed={ this.onThirdChange.bind(this) }
              uniquePrefix="ElementSelector__third-selectbox"
              current={ this.state.currentThird }
              emptyDropdownTitle={ 'Please, select a ' + this.props.thirdName }
              dictionary={
                this.state.currentSecond ? this.state.elements[this.state.currentFirst.id].data[this.state.currentSecond.id].data : {}
              }/>
          </Col>
        }
        {
          this.props.embedChildrenInline && this.props.children
            ? <Col xs={ this.props.size || 3 } className={ classes.selectorColumn }>
              { this.props.children }
            </Col>
            : null
        }
      </Row>
      {
        this.props.totals
          ? <Row>
            <Col>
              { this.props.totals }
            </Col>
          </Row>
          : null
      }
      {
        !this.props.embedChildrenInline && this.props.children
          ? this.props.children
          : null
      }
    </>;
  }
}

ElementSelector.propTypes = {
  elementChanged: PropTypes.func.isRequired,
  thirdName: PropTypes.string.isRequired,
  secondName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  elementsUrl: PropTypes.string.isRequired,
  size: PropTypes.number,
  totals: PropTypes.objectOf(ElementTotals),
  initThird: PropTypes.number,
  initSecond: PropTypes.number,
  initFirst: PropTypes.number,
  lazy: PropTypes.bool,
  embedChildrenInline: PropTypes.bool,
  hideNoChoice: PropTypes.bool
};


export default ElementSelector;
