import { Button, Jumbotron } from 'react-bootstrap';
import React from 'react';

import classes from './SignOutView.module.css';

const SignOutView = () => <Jumbotron className={ classes.wrapper }>
  <h3 className={ classes.messageRow }>Thanks for using Roamingaudit!</h3>
  <div className={ classes.messageRow }>You are now logged out.</div>
  <div className={ classes.messageRow }>
    <Button variant="primary" href="/sign-in" block>Log In Again</Button>
  </div>
  <a href="https://roamingaudit.com">Visit Roamingaudit.com</a>
</Jumbotron>;

export default SignOutView;
