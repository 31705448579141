export default () => {
  self.fetch||(self.fetch=function(e,n){return n=n||{},new Promise(function(t,s){var r=new XMLHttpRequest,o=[],u=[],i={},a=function(){return{ok:2==(r.status/100|0),statusText:r.statusText,status:r.status,url:r.responseURL,text:function(){return Promise.resolve(r.responseText)},json:function(){return Promise.resolve(JSON.parse(r.responseText))},blob:function(){return Promise.resolve(new Blob([r.response]))},clone:a,headers:{keys:function(){return o},entries:function(){return u},get:function(e){return i[e.toLowerCase()]},has:function(e){return e.toLowerCase()in i}}}};for(var c in r.open(n.method||"get",e,!0),r.onload=function(){r.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm,function(e,n,t){o.push(n=n.toLowerCase()),u.push([n,t]),i[n]=i[n]?i[n]+","+t:t}),t(a())},r.onerror=s,r.withCredentials="include"==n.credentials,n.headers)r.setRequestHeader(c,n.headers[c]);r.send(n.body||null)})});

  function LongPolling () {
    var lp = this;

    lp.isPollingRunning = false;
    lp.pollingProcess = null;
    lp.delayInterval = null;

    lp.start = function(url, payload = {}, delaySec) {
      if (lp.isPollingRunning) {
        throw new Error("[LongPollingService] Polling is already running, stop it before running again.");
      }
      lp.isPollingRunning = true;
      if (delaySec) {
        lp.delayInterval = setTimeout(() => {
          lp.delayInterval = null;
          lp.poll(url, payload);
        }, delaySec * 1000);
      } else {
        lp.poll(url, payload);
      }
    };

    lp.stop = function () {
      lp.isPollingRunning = false;

      if (lp.pollingProcess !== null) {
        return lp.pollingProcess.finally(() => {
          lp.pollingProcess = null;
        });
      }

      if (lp.delayInterval !== null) {
        clearTimeout(this.delayInterval);
      }

      return Promise.resolve(null);
    };

    lp.isRunning = function () {
      return lp.isPollingRunning;
    };

    lp.poll = function (url, payload) {
      if (!lp.isPollingRunning) {
        return;
      }

      lp.pollingProcess = lp.performPollingRequest(url, payload)
        .then((response) => {
          return response.json();
        }).then((data) => {
          self.postMessage(data);
          setTimeout(() => {
            payload.hash = data.hash;
            lp.poll(url, payload);
          }, 0);
        }).catch((e) => {
          if (401 === e.status) {
            lp.stop();
          } else if (-1 === [504, 408, 520].indexOf(e.status)) {
            setTimeout(
              lp.poll.bind(self, url, payload),
              30 * 1000
            );
          } else {
            lp.poll(url, payload);
          }
        });
    };

    lp.performPollingRequest = function (url, payload) {
      return self.fetch(
        url,
        {
          method: 'POST',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload),
          credentials: 'omit'
        }
      );
    }
  }

  var LongPollingService = new LongPolling();
  var isStarted = false;
  self.addEventListener('message', function(event) {
    switch (event.data.cmd) {
      case 'start':
        if (!isStarted) {
          LongPollingService.start(event.data.url, event.data.payload);
          isStarted = true;
        }
        break;
      case 'stop':
        if (isStarted) {
          isStarted = false;
          LongPollingService.stop().then(function () {
            self.close()
          });
        }
        break;
    }
  }, false);
};

