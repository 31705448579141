import React  from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Modal } from 'react-ui-utils';
import { LiteTable } from 'react-lite-table';

import { ElementStats } from './ElementStats';

const ElementTotalsModal = props => <Modal
  size="xl"
  actions={ <Button variant="secondary" onClick={ () => props.hideModal() }>Close</Button> }
  hideModal={ props.hideModal }
  title="IR.21 Data"
  scrollable={ true }>
      {
        props.stats.data && props.stats.fields
          ? <LiteTable rows={ props.stats.data } columns={ props.stats.fields } withFiltering/>
          : null
      }
  </Modal>;

ElementTotalsModal.propTypes = {
  stats: PropTypes.objectOf(ElementStats),
  hideModal: PropTypes.func,
  onConfirm: PropTypes.func
};

export default ElementTotalsModal;
