import React, { useState } from 'react';

import HeaderTable from './HeaderTable/HeaderTable';
import CheckTable from './CheckTable/CheckTable';

import classes from './ManualCheck.module.css';

const ManualCheck = props => {
  const initialQty = props.checkInfo.data ? props.checkInfo.data.length : 0;
  const [filteredQty, setFilteredQty] = useState(initialQty);

  return <div className={ classes.tablesContainer }>
    <div className={ classes.filteringStats}>Showing { filteredQty } out of { initialQty }</div>
    <div className={ classes.manualCheckHeader }>
      <HeaderTable columns={ [ 'Brand', 'Country', 'TADIG', 'CC', 'NDC', 'Start', 'Stop' ]} rows={ [ props.entry ] }/>
    </div>
    <div className={ classes.manualCheckTables }>
      { props.children }
      <CheckTable filters={ { cc: '^' + props.entry.cc, ndc: '^' + props.entry.ndc } }
                  rows={ props.checkInfo.data }
                  onFilter={ qty => setFilteredQty(qty) }
                  columns={ props.checkInfo.columns }/>
    </div>
  </div>;
};

export default ManualCheck;
