import React from 'react';
import { Switch } from 'react-router-dom';
import { ReactReduxContext } from 'react-redux';

import Page from './components/Page/Page';
import RunAuditView from './containers/Audit/RunAuditView/RunAuditView';
import ResultsView from './containers/Audit/ResultsView/ResultsView';
import TemplatesView from './containers/Template/TemplatesView/TemplatesView';
import Layout from './components/Layout/Layout';
import SignInView from './containers/Auth/SignInView/SignInView';
import SignOutView from './containers/Auth/SignOutView/SignOutView';
import TemporaryDeactivatedView from './containers/Auth/TemporaryDeactivatedView/TemporaryDeactivatedView';
import BannedView from './containers/Auth/BannedView/BannedView';
import IpSecurityView from './containers/Security/IpSecurityView/IpSecurityView';
import TastesEnum from './components/Layout/TastesEnum';
import TemplateComparisonView from './containers/Template/TemplateComparisonView/TemplateComparisonView';
import FilterView from './containers/Security/FilterView/FilterView';
import ConfigurationView from './containers/Configuration/ConfigurationView/ConfigurationView';
import SteeringView from './containers/Steering/SteeringView/SteeringView';
import OperatorListView from './containers/Operator/OperatorListView/OperatorListView';
import EditOperatorView from './containers/Operator/EditOperatorView/EditOperatorView';
import Aa14InboxView from './containers/InfoCenter/Aa14InboxView/Aa14InboxView';
import Ir21InboxView from './containers/InfoCenter/Ir21InboxView/Ir21InboxView';

const App = () => {
  return <Switch>
    <Page path="/sign-in" title="Sign In" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.GUEST }>
        <SignInView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/sign-out" title="Sign Out" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.GUEST }>
        <SignOutView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/temporarily-deactivated" title="Temporary Deactivated" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.GUEST }>
        <TemporaryDeactivatedView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/banned" title="Banned" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.GUEST }>
        <BannedView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/audit" title="Run Audit" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <RunAuditView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/audit/results/:vendor/:type/:subtype" title="Audit Results" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <ResultsView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/audit/results" title="Audit Results" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <ResultsView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/templates" title="Templates" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <TemplatesView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/templates/compare/:vendor/:type/:subtype"
          title="Template Comparison"
          exact
          context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <TemplateComparisonView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/security/ip" title="IP Security" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <IpSecurityView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/security/filter" title="Perfect Filter" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <FilterView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/configuration" title="Network Elements" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <ConfigurationView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/steering" title="Steering" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <SteeringView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/operators" title="Operators List" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <OperatorListView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/operators/create" title="Create Operator" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <EditOperatorView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/operators/:id" title="Operator" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <EditOperatorView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/info/ir21" title="IR.21 Inbox" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <Ir21InboxView context={ ReactReduxContext }/>
      </Layout>
    </Page>
    <Page path="/info/aa14" title="AA.14 Inbox" exact context={ ReactReduxContext }>
      <Layout taste={ TastesEnum.CLIENT }>
        <Aa14InboxView context={ ReactReduxContext }/>
      </Layout>
    </Page>
  </Switch>;
}

export default App;
