import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import classes from './TabSelector.module.css';

const TabSelector = props => {
  let tabs;
  if (Array.isArray(props.list)) {
    tabs = props.list.map(
      (name, index) => {
        return <li onClick={ () => props.clicked(name) }
                   id={ 'TabSelector__tab-' + name }
                   className={ 'TabSelector__tab ' + ' ' + (props.current && name === props.current ? classes.tab
                     + ' ' + classes['tab--selected'] : classes.tab) }>
          { name + (props.refs ? (' - ' + (props.refs[name] || 0)) : '') }
        </li>
      })
  } else if (Array.isArray(props.dictionary)) {
    tabs = props.dictionary.map(
      ({ id, name }) => {
        return <li onClick={ () => props.clicked({ id, name }) }
                   id={ 'TabSelector__tab-' + name }
                   className={ 'TabSelector__tab ' + ' ' + (props.current && id === props.current.id ? classes.tab
                     + ' ' + classes['tab--selected'] : classes.tab) }>
          { name + (props.refs ? (' - ' + (props.refs[id] || 0)) : '') }
        </li>
      });
  } else {
    tabs = Object.entries(props.dictionary).map(
      ([ id, val ]) => {
        return <li onClick={ () => props.clicked({ id, name: val.name }) }
                   id={ 'TabSelector__tab-' + val.name }
                   className={ 'TabSelector__tab ' + ' ' + (props.current && id === props.current.id.toString() ? classes.tab
                     + ' ' + classes['tab--selected'] : classes.tab) }>
          { val.name }
          { val.ref ? (' - ' + (val.ref || 0)) : '' }
        </li>
      });
  }

  return <Row>
    <Col xs="12">
      <ul id="TabSelector__list" className={ classes.tabsContainer }>
        { tabs }
      </ul>
      <div className={ classes.tabHeading }>{ props.children }</div>
    </Col>
  </Row>
};

TabSelector.propTypes = {
  clicked: PropTypes.func.isRequired,
  refs: PropTypes.array,
  dictionary: PropTypes.oneOfType([ PropTypes.object, PropTypes.arrayOf(PropTypes.object) ]),
  current: PropTypes.object,
  list: PropTypes.arrayOf(PropTypes.string)
};

export default TabSelector;
