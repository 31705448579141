import React, { Component } from 'react';
import { withLoader, withNotifications } from 'react-ui-utils';
import { Col, Form, Row } from 'react-bootstrap';

import axios from '../../../services/axios';
import OptimizedFilterTable from './OptimizedFilterTable/OptimizedFilterTable';
import TabSelector from '../../../components/TabSelector/TabSelector';
import DropdownSearch from '../../../components/DropdownSearch/DropdownSearch';
import ResultsTable from './ResultsTable/ResultsTable';
import UnwantedResultTable from './UnwantedResultTable/UnwantedResultTable';

class FilterView extends Component {
  state = {
    rows: [],
    tabs: [],
    networkElements: [],
    filteredQty: 0,
    dataSet: 'nngt',
    currentTab: null,
    currentNetworkElement: null,
  };

  tabs = [
    'Perfect Filter',
    'Perfect Match Found',
    'Perfect Match Missing',
    'Short ranges',
    'Long ranges',
    'Unwanted'
  ];

  componentDidMount() {
    this.fetchNetworkElements();
  }

  async fetchNetworkElements() {
    this.props.showLoader();

    try {
      const response = await axios.get('/security/filter/network-elements');

      this.setState({ networkElements: response.data });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  async fetchFilter() {
    this.props.showLoader();

    try {
      const response = await axios.get(`/security/filter/audit/${ this.state.dataSet }/${ this.state.currentNetworkElement.id }`);

      this.setState({ ...response.data, currentTab: 0 });
    } catch (e) {
      this.props.processError(e);
    }

    this.props.hideLoader();
  }

  filterHandler(qty) {
    if (this.state.filteredQty !== qty) {
      this.setState({ filteredQty: qty });
    }
  }

  targetDatasetChangeHandler(dataSet) {
    this.setState({ dataSet }, () => {
      if (this.state.currentNetworkElement) {
        this.fetchFilter.bind(this);
      }
    });
  }

  tabChangeHandler(tab) {
    this.setState({ currentTab: this.tabs.indexOf(tab) });
  }

  networkElementChangeHandler(networkElement) {
    this.setState({ currentNetworkElement: networkElement }, this.fetchFilter.bind(this));
  }

  render() {
    let CurrentTable;

    switch (this.state.currentTab) {
      case 0:
        CurrentTable = OptimizedFilterTable;
        break;
      case 5:
        CurrentTable = UnwantedResultTable;
        break;
      default:
        CurrentTable = ResultsTable;
        break;
    }

    return <>
      <Row className="pb-4">
        <Col xs="auto">
          <Form.Control
            as="select"
            name="dataSource"
            value={ this.state.dataSet }
            onChange={ e => this.targetDatasetChangeHandler(e.target.value) }>
            <option value="nngt">NNGT</option>
            <option value="msisdn">MSISDN</option>
          </Form.Control>
        </Col>
        <Col xs="3">
          <DropdownSearch
            current={ this.state.currentNetworkElement }
            emptyDropdownTitle="Select a Network Node"
            dictionary={ this.state.networkElements }
            changed={ this.networkElementChangeHandler.bind(this) }
            uniquePrefix="perfect-filter__network-elements"/>
        </Col>
      </Row>
      {
        this.state.currentTab !== null ?
          <>
            <TabSelector clicked={ this.tabChangeHandler.bind(this) }
                         list={ this.tabs }
                         current={ this.tabs[this.state.currentTab] }>
              Entries: { this.state.filteredQty }
            </TabSelector>
            <CurrentTable
              key={ this.state.currentTab }
              rows={ this.state.rows[this.state.currentTab] }
              onFilter={ this.filterHandler.bind(this) }/>
          </>
          : null
      }
    </>;
  }
}

export default withLoader(withNotifications(FilterView));
