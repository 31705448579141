import { Form } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';

import classes from './RawSwitch.module.css';

const RawSwitch = props => <Form.Group className={ `${ classes.switchControl } ${ props.className }` } controlId={ props.id }>
  <Form.Switch
    inline
    defaultValue={ true }
    name={ props.name }
    label={ props.label }
    id={ props.id }
    checked={ props.isChecked }
    onChange={ props.onChange }/>
</Form.Group>;

RawSwitch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

export default RawSwitch;
