export const NOTIFICATION_ADD = 'NOTIFICATION_ADD';
export const NOTIFICATION_DROP = 'NOTIFICATION_DROP';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';

export const EVENT_SUBSCRIBE = 'EVENT_SUBSCRIBE';
export const EVENT_UNSUBSCRIBE = 'EVENT_UNSUBSCRIBE';

export const USER_UPDATE = 'USER_UPDATE';

export const TITLE_UPDATE = 'TITLE_UPDATE';
