import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-ui-utils';

import ConfigurationFilesTable from './ConfigurationFilesTable/ConfigurationFilesTable';

const ConfigurationFilesModal = props => <Modal
  size="lg"
  actions={ <Button variant="secondary" onClick={ props.hideModal }>Close</Button> }
  hideModal={ props.hideModal }
  title="Uploaded files">
  <ConfigurationFilesTable rows={ props.rows } columns={ props.columns }/>
</Modal>;

ConfigurationFilesModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default ConfigurationFilesModal;
