import { LiteTable } from 'react-lite-table';
import React from 'react';

const SteeringTable = props => <LiteTable
  rows={ props.rows }
  columns={ props.columns }
  onFilter={ props.onFilter }
  columnsMap={ {
    'organizationName': 'Organization Name',
    'brand': 'Brand',
    'country': 'Country',
    'iso3166': 'ISO3166',
    'tadig': 'TADIG',
    'hub': 'Hub',
    'mcc': 'MCC',
    'mnc': 'MNC',
    'cc': 'CC',
    'ndc': 'NDC',
    'rangeStart': 'Range Start',
    'rangeStop': 'Range Stop',
    'raexVersion': 'RAEX Version',
    'lastUpdate': 'Last Update'
  } }
  withFiltering/>

export default SteeringTable;
