import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-ui-utils';

import { LiteTable } from 'react-lite-table';

const ResultDetailComparisonModal = props => {
  return <Modal
    size="xl"
    hideModal={ props.hideModal }
    title="Results Comparison">
    <LiteTable
      fixedRows={ [
        { ...props.data.template, customStyle: { color: '#17a2b8' } },
        { ...props.data.current, customStyle: { color: '#dc3545' } }
      ] }
      columns={ props.data.columns }
      rows={ props.data.related }/>
  </Modal>;
};

ResultDetailComparisonModal.propTypes = {
  data: PropTypes.object,
  hideModal: PropTypes.func
};

export default ResultDetailComparisonModal;
