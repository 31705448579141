import React  from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-ui-utils';

import ManualCheck from '../ManualCheck/ManualCheck';
import RowAnalysisTable from './RowAnalysisTable/RowAnalysisTable';

import classes from './RowAnalysisModal.module.css';

const RowAnalysisModal = props => {
  return <Modal
    size="lg"
    hideModal={ props.hideModal }
    title={
      <div className={ classes.modalHeader }>
        <div className={ classes.modalHeaderText }>Manually analyze</div>
        <div>
          <Form.Control
            type="text" value={ props.nsFilterInitialValue }
            onChange={ e => props.onNsFilterChange(e.target.value) }/>
        </div>
      </div>
    }>
    <ManualCheck checkInfo={ props.checkInfo } entry={ props.checkEntry }>
      <RowAnalysisTable rows={ props.rowAnalysisEntries } columns={ [ 'ns' ] }/>
    </ManualCheck>
  </Modal>;
};

RowAnalysisModal.propTypes = {
  nsFilterInitialValue: PropTypes.number.isRequired,
  onNsFilterChange: PropTypes.func.isRequired,
  rowAnalysisEntries: PropTypes.array.isRequired,
  checkEntry: PropTypes.object.isRequired,
  checkInfo: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default RowAnalysisModal;
