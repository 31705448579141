import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { faChartBar, faFileExport, faPencilAlt, faPlus, faTasks, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LiteTable } from 'react-lite-table';

const TemplateComparisonTable = props => {
  const actions = row => {
    const actions = [];

    if (!Array.isArray(props.actions)) {
      return actions;
    }

    if (props.actions.indexOf('add') !== -1) {
      actions.push(
        <Button
          variant="primary"
          size="sm"
          data-kind="add-to-templates-or-partners"
          title={ 'Add entry to ' + (row.resultCode && row.resultCode === 2 ? 'Template' : 'Roaming Profile') }
          onClick={ e => {
            e.stopPropagation();
            if (row.resultCode && row.resultCode === 2) {
              props.addToTemplates(row);
            } else {
              props.addToRoamingProfile(row);
            }
          } }>
          <FontAwesomeIcon icon={ faPlus }/>
        </Button>
      );
    }

    if (props.actions.indexOf('add_ra') !== -1) {
      actions.push(
        <Button
          variant="primary"
          size="sm"
          data-kind="add-to-templates-roamingarea"
          title="Add entry to Template"
          onClick={ e => {
            e.stopPropagation();
            props.addToRoamingAreaTemplate(row);
          } }>
          <FontAwesomeIcon icon={ faPlus }/>
        </Button>
      );
    }

    if (props.actions.indexOf('edit') !== -1) {
      actions.push(
        <Button
          variant="success"
          size="sm"
          data-kind="edit-template"
          title="Edit template entry"
          onClick={ e => {
            e.stopPropagation();
            props.editTemplateEntry(row);
          } }>
          <FontAwesomeIcon icon={ faPencilAlt }/>
        </Button>
      );
    }

    if (props.actions.indexOf('analyze') !== -1) {
      actions.push(
        <Button
          variant="primary"
          size="sm"
          data-kind="analyze-roamingarea"
          title="Analyze entry"
          onClick={ e => {
            e.stopPropagation();
            props.showCheckForRow(row, true);
          } }>
          <FontAwesomeIcon icon={ faChartBar }/>
        </Button>
      );
    }

    if (props.actions.indexOf('check') !== -1) {
      actions.push(
        <Button
          variant="primary"
          size="sm"
          data-kind="check-roamingarea"
          title="Check entry"
          onClick={ e => {
            e.stopPropagation();
            props.showCheckForRow(row);
          } }>
          <FontAwesomeIcon icon={ faTasks }/>
        </Button>
      );
    }

    if (props.actions.indexOf('exc_imsi') !== -1) {
      actions.push(
        <Button
          variant="info"
          size="sm"
          data-kind="add-imsi-to-exceptions"
          title="Add IMSI to Exceptions"
          onClick={ (e) => {
            e.stopPropagation();
            props.addImsiToException(row);
          } }>
          <FontAwesomeIcon icon={ faFileExport }/>
        </Button>
      );
    }

    if (props.actions.indexOf('exc_ra') !== -1) {
      actions.push(
        <Button
          variant="info"
          size="sm"
          data-kind="add-ra-to-exceptions"
          title="Add Roaming Area to Exceptions"
          onClick={ (e) => {
            e.stopPropagation();
            props.addRoamingAreaToException(row);
          } }>
          <FontAwesomeIcon icon={ faFileExport }/>
        </Button>
      );
    }

    if (props.actions.indexOf('del_exc') !== -1) {
      actions.push(
        <Button
          variant="warning"
          size="sm"
          data-kind="remove-from-exceptions"
          title="Remove from Exceptions"
          onClick={ e => {
            e.stopPropagation();
            props.removeException(row);
          } }>
          <FontAwesomeIcon icon={ faTimes }/>
        </Button>
      );
    }

    if (props.actions.indexOf('del_tmpl') !== -1) {
      actions.push(
        <Button
          variant="danger"
          size="sm"
          data-kind="remove-from-templates-or-partners"
          title={ 'Remove from ' + (row.resultCode && row.resultCode === 2 ? 'Roaming Profile' : 'Template') }
          onClick={ e => {
            e.stopPropagation();
            if (row.resultCode && row.resultCode === 2) {
              props.removeFromRoamingProfile(row);
            } else {
              props.removeFromTemplates(row);
            }
          } }>
          <FontAwesomeIcon icon={ faTimes }/>
        </Button>
      );
    }

    return actions;
  }

  return <LiteTable
    withFiltering
    cellValueResolver={ (row, column) => {
      if (column === 'start') {
        return row['range_start'];
      }

      if (column === 'stop') {
        return row['range_stop'];
      }

      return row[column];
    } }
    actions={ actions }
    columns={ props.columns }
    rows={props.rows}/>;
};

TemplateComparisonTable.propTypes = {
  actions: PropTypes.array,
  addToRoamingAreaTemplate: PropTypes.func,
  addRoamingAreaToException: PropTypes.func,
  editTemplateEntry: PropTypes.func,
  addToRoamingProfile: PropTypes.func,
  addToTemplates: PropTypes.func,
  addImsiToException: PropTypes.func,
  removeException: PropTypes.func,
  removeFromTemplates: PropTypes.func,
  removeFromRoamingProfile: PropTypes.func,
  showCheckForRow: PropTypes.func
};

export default TemplateComparisonTable;
