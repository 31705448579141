import { USER_UPDATE } from '../actions/actionTypes';
import Bugsnag from '@bugsnag/js';

const initialState = {
  user: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_UPDATE:
      const newState = { ...state };
      newState.user = { ...action.user };
      Bugsnag.addMetadata('user', action.user);
      return newState;
    default:
      return state;
  }
};

export default reducer;
